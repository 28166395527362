import React from 'react'
import './ButtonStyle.css'

const Button = ({ svgIcon, className, onClick, name, src, disabled }) => {
    return (
        <button className={`button ${className}`} onClick={onClick} disabled={disabled}>
            {src && <img src={src} alt="" />}
            {
                svgIcon && svgIcon
            }
            {name}
        </button>
    )
}

export default Button
