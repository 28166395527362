import React from 'react'
import { Link } from 'react-router-dom';
function OverView({ isActiveTab }) {
  return (
    <div className={`userWrapperprofile MentorMainProfileWrapper MentorOverviewData ${isActiveTab && 'active'}`}>
      <div className="border-btm  w-100">
        <div className="Activitiesheading  flex-between w-100">
          <h2 className="heading-2">Call Details</h2>
        </div>
      </div>
      {/* Body */}
      <div className="Bodymentordatawrap OverViewProfilePage">
        <div className="dataWrapperMenteeListitems">
          <div className="leftheadingListMentee">
            <div className="leftHeadingmenteelist">First Name</div>
            <div className="leftHeadingmenteelist">Last Name</div>
            <div className="leftHeadingmenteelist">Designation</div>
            <div className="leftHeadingmenteelist">Phone Number	</div>
            <div className="leftHeadingmenteelist">Email</div>
            <div className="leftHeadingmenteelist">Linkedin</div>
          </div>

          <div className="RightListDataMenteeProfile">
            <div className="rightdatamenteelist">Nimisha</div>
            <div className="rightdatamenteelist">Shrivastava</div>
            <div className="rightdatamenteelist">Senior Finance Manager</div>
            <div className="rightdatamenteelist">+91 9876 543 210</div>
            <div className="rightdatamenteelist">nimishashrivastava21@gmail.com</div>
            <div className="rightdatamenteelist">
              <Link className='SocialLink' target='_blank' to='https://linkedin.com/profilelink-myprofile'>
                https://linkedin.com/profilelink-myprofile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverView