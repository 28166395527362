import React from 'react';
import './MentorsProfileStyle.css';
import { Link } from 'react-router-dom';
function OverviewMentors({ isActiveTab, data }) {
    return (
        <div className={`ContainerTabForAllTabData MentorOverviewData ${isActiveTab && 'active'}`}>
            <div className='Headingforallheader'>
                <div className="Activitiesheading  flex-between w-100">
                    <h2 className="heading-2">Profile Details</h2>
                </div>
            </div>

            <div className="border-btm  w-100"></div>

            {/* Body */}
            <div className="bodyDataForallUserdata">
                <div className="Bodymentordatawrap">
                    <div className="dataWrapperMenteeListitems">
                        <div className="leftheadingListMentee">
                            <div className="leftHeadingmenteelist">First Name</div>
                            <div className="leftHeadingmenteelist">Last Name</div>
                            <div className="leftHeadingmenteelist">Designation</div>
                            <div className="leftHeadingmenteelist">Company</div>
                            <div className="leftHeadingmenteelist">Phone Number	</div>
                            <div className="leftHeadingmenteelist">Email</div>
                            <div className="leftHeadingmenteelist">Linkedin</div>
                        </div>

                        <div className="RightListDataMenteeProfile">
                            <div className="rightdatamenteelist">{data?.firstName ? data?.firstName : "---"}</div>
                            <div className="rightdatamenteelist">{data?.lastName ? data?.lastName : "---"}</div>
                            <div className="rightdatamenteelist">{data?.profession?.designation ? data?.profession?.designation : "---"}</div>
                            <div className="rightdatamenteelist">{data?.profession?.company ? data?.profession?.company : "---"}</div>
                            <div className="rightdatamenteelist">{data?.mobileNumber ? data?.mobileNumber : "---"}</div>
                            <div className="rightdatamenteelist">{data?.email ? data?.email : "---"}</div>
                            <div className="rightdatamenteelist">
                                {data?.linkedin ? <Link className='SocialLink' target='_blank' to={data?.linkedin}>
                                    {data?.linkedin ? data?.linkedin : "---"}
                                </Link> :
                                    <span>{"---"}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Dashborder"></div>
                <div className="Bodymentordatawrap">
                    <div className="dataWrapperMenteeListitems">
                        <div className="RightListDataMenteeProfile leftheadingListMentee">
                            <div className="leftHeadingmenteelist">Professional Interest</div>
                            <div className="leftHeadingmenteelist">Mentor on</div>
                            <div className="leftHeadingmenteelist">Years of Experience</div>
                        </div>

                        <div className="RightListDataMenteeProfile">
                            <div className="rightdatamenteelist">{data?.interests?.length > 0 ? data?.interests[0]?.interest : "---"}</div>
                            <div className="rightdatamenteelist">{data?.topics?.length > 0 ? data?.topics[0]?.Topic : "---"}</div>
                            <div className="rightdatamenteelist">{data?.profession?.yrsOfExp ? `${data?.profession?.yrsOfExp} years` : "---"}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OverviewMentors