import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import "./MyMentorsStyle.css";
import InputField2 from "../../components/InputField2/InputField2";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { addUser, clearState, superAdminData } from "../../reducer/superAdminSlice";
import { useNavigate } from "react-router-dom";
import SuccessToast from "../../components/Toast/SuccessToast";
// import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
const AddMentor = () => {
  //==Dropdown value==
  const interestOptions = [
    "Taxation",
  "CA/CMA/CFA",
  "CAT/MBA",
  "Financial Advisory",
  "Investment Banking",
  "Auditing",
  "FP&A",
  "Financial Reporting",
  "Mergers & Acquisitions",
  "Accounting",
  "Financial Analysis",
  "Risk Management",
  "Asset Management",
  "Valuations"];

const topicOptions = [
      "Career Advice",
      "Job Search",
      "Skill Development",
      "Competitive Exams",
      "Career Transition",
];

  const template = {
    profileImg: {
      name: "",
      url: "",
    },
    firstName: "",
    lastName: "",
    designation: "",
    company: "",
    mobileNumber: "",
    email: "",
    linkedIn: "",
    interests: [],
    topics: [],
    exp: "",
  };

  const errorTemplate = {
    firstName: "",
    lastName: "",
    designation: "",
    company: "",
    mobileNumber: "",
    email: "",
    linkedIn: "",
    interests: "",
    topics: "",
    exp: "",
  };

  //Data states
  const [addMentor, setAddMentor] = useState(template);

  //Error states
  const [errorAddMentor, setErrorAddMentor] = useState(errorTemplate);

  //Loading states
  const [isLoading, setIsLoading] = useState(false);

  //Toast states
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { isFetching, isUserAddedSuccess, isError, errorMessage } = useSelector(superAdminData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setErrorAddMentor(errorTemplate);
    const { name, value } = e.target;
    if (name === "mobileNumber" && (value?.length > 10 || value < 0)) return;
    setAddMentor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (e) => {
    setErrorAddMentor(errorTemplate);
    const { name, value } = e.target;
    setAddMentor(prev => ({
      ...prev,
      [name]: [value]
    }))
  }

  const handleUploadProfile = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files[0];
      setIsLoading(true);
      const data = new FormData();

      data.append("file", files);
      data.append("upload_preset", "qlm4yp8h");
      data.append("cloud_name", "df491ehfg");

      fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {


          setIsLoading(false);
          setAddMentor(prev => ({
            ...prev,
            profileImg: {
                name: res.original_filename,
                url: res.secure_url
            }
          }));
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const validate = () => {
    for (const key in errorAddMentor) {
      if (!addMentor[key]) {
        if (key === "lastName") continue;
        setErrorAddMentor((prev) => ({
          ...prev,
          [key]: "This field is mandatory",
        }));
        return true;
      }
    }
  };

  const handleDiscard = () => {
    setAddMentor(template);
  };

  const handleSave = () => {
    if (!validate()) {
      const payload = addMentor;
      payload["profileImg"] = addMentor["profileImg"]["url"];
      const obj = {
        user: "mentor",
        orgId: "913c7340-39f2-47b8-aefa-cb234f59f063",
        obj: payload
      }
      dispatch(addUser(obj));
    }
  };

  useEffect(() => {
    if(isUserAddedSuccess) {
      setIsShowSuccess(true);
      setSuccessMessage("Success");
      setTimeout(() => {
        navigate("/mymentors");
      },2000)
      dispatch(clearState());
    }
  },[isUserAddedSuccess])

  return (
    <>
    {(isFetching || isLoading) && <PageLoader />}
    <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className="MyMentorsWrapper">
      <div className="MymentorsHeading flex gap-10">
                <img src="/assets/img/BackArrow.png" alt="" className='pointer' onClick={() => { navigate('/mymentors') }} />
                <div className="headingwrap">
                    <h2 className="heading-2">Add Mentor</h2>
                    {/* <p className='text2'>Back to viewing All Organizations</p> */}
                </div>
            </div>

        <div className="addMentorWrapper">
          <div className="userProfileEdit flexbox">
            <h4 className="lableHeading">Avatar</h4>

            <div className="leftWrapperPrfile">
              <div class="userProfileEditTab">
                <label class="userProfile pointer" for="bgimg">
                  <img
                    src={
                      addMentor["profileImg"]["url"]
                        ? addMentor["profileImg"]["url"]
                        : "/assets/img/user1.png"
                    }
                    alt=""
                    className="userimg"
                  />
                  <input type="file" accept='image/*' name="bgimg" id="bgimg" onChange={handleUploadProfile} />
                  <img
                    src="/assets/img/edit.png"
                    alt=""
                    class="editimg pointer"
                  />
                </label>
              </div>
              <p className="mt-10 text2">
                {addMentor["profileImg"]["name"]
                        ? addMentor["profileImg"]["name"]
                        : "File types allowed are: jpg, jpeg, png"}
              </p>
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">
              Full Name<span className="impRed"> *</span>
            </h4>
            <div className="multipleFormInput flex-center inputalineerroe">
              <InputField2
                errorMessage={errorAddMentor["firstName"]}
                name={"firstName"}
                value={addMentor["firstName"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"First Name"}
              />
              <InputField2
                errorMessage={errorAddMentor["lastName"]}
                name={"lastName"}
                value={addMentor["lastName"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Last Name"}
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">
              Designation<span className="impRed"> *</span>
            </h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["designation"]}
                name={"designation"}
                value={addMentor["designation"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Input designation"}
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">
              Company<span className="impRed"> *</span>
            </h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["company"]}
                name={"company"}
                value={addMentor["company"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Input company name"}
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">
              Phone Number<span className="impRed"> *</span>
            </h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["mobileNumber"]}
                type={"number"}
                name={"mobileNumber"}
                value={addMentor["mobileNumber"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Input contact number"}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">
              Email<span className="impRed"> *</span>
            </h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["email"]}
                name={"email"}
                value={addMentor["email"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Input email id"}
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">Linkedin Profile</h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["linkedIn"]}
                name={"linkedIn"}
                value={addMentor["linkedIn"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"Input linkedin profile link"}
              />
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox mob-dropdown">
            <h4 className="lableHeading">Professional Interest</h4>
            <div className="multipleFormInput flex-center">
              <select
                className="customDropdownWrap"
                onChange={handleDropdownChange}
                name="interests"
              >
                <option
                  className="menuList"
                  style={{ display: "none" }}
                  defaultChecked
                >
                  Select your Domain of Expertise
                </option>
                {interestOptions.map((item, index) => (
                  <option
                    key={index}
                    selected={item === addMentor["interests"]}
                    className="menuList"
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
              {/* {errorAddMentor["interests"] && <span className='FormError'>{errorAddMentor["interests"]}</span>} */}
              {/* <CustomDropdown name={"interests"} onClick={handleChange} label={'Admission Fee'} isImp={true} className={'filterDrop'} selected={'Select your Domain of Expertise'}  
                         options={interestOptions} /> */}
            </div>
          </div>
                         {console.log(addMentor)}
          <div className="mulipleFormInputWrap flexbox mob-dropdown">
            <h4 className="lableHeading">Mentor On</h4>
            <div className="multipleFormInput flex-center">
              <select
                className="customDropdownWrap"
                onChange={handleDropdownChange}
                name="topics"
              >
                <option
                  className="menuList"
                  style={{ display: "none" }}
                  defaultChecked
                >
                  Select your topic of mentoring
                </option>
                {topicOptions.map((item, index) => (
                  <option
                    key={index}
                    selected={item === addMentor["topics"]}
                    className="menuList"
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
              {/* {errorAddMentor["topics"] && <span className='FormError'>{errorAddMentor["topics"]}</span>} */}
            </div>
          </div>

          <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">Years of Experience</h4>
            <div className="multipleFormInput flex-center">
              <InputField2
                errorMessage={errorAddMentor["exp"]}
                type={"mobileNumber"}
                name={"exp"}
                value={addMentor["exp"]}
                onChange={handleChange}
                notImp={true}
                placeholder={"8"}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </div>
          </div>

          <div className="FooterAdminPopup ">
            <Button
              name={"Discard"}
              // className={"buttontext"}
              onClick={handleDiscard}
              className={'buttontext GreyFillButton'}
            />
            <Button
              name={"Save Changes"}
              // className={"active buttontext"}
              onClick={handleSave}
              className={' buttontext BlueFillButton'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMentor;
