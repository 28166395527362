import React, { useState } from 'react'
import Button from '../../components/Button/Button';
import SearchBar from '../../components/SearchBar/SearchBar';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import { useNavigate } from 'react-router-dom';
import DropdownCustom from '../../components/DropdownCustom/DropdownCustom';
import './ConfigureStyle.css'
import AdPlansCard from '../../components/AdPlansCard/AdPlansCard';
import CustomPlanCard from '../../components/AdPlansCard/CustomPlanCard';

const Configure = () => {
    const addsvg = [
        <svg className='pluseSvgicon' xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="#fff">
            <path d="M6.99996 12.7712C3.54079 12.7712 0.729126 9.95949 0.729126 6.50033C0.729126 3.04116 3.54079 0.229492 6.99996 0.229492C10.4591 0.229492 13.2708 3.04116 13.2708 6.50033C13.2708 9.95949 10.4591 12.7712 6.99996 12.7712ZM6.99996 1.10449C4.02496 1.10449 1.60413 3.52533 1.60413 6.50033C1.60413 9.47533 4.02496 11.8962 6.99996 11.8962C9.97496 11.8962 12.3958 9.47533 12.3958 6.50033C12.3958 3.52533 9.97496 1.10449 6.99996 1.10449Z" fill="white" />
            <path d="M9.33329 6.9375H4.66663C4.42746 6.9375 4.22913 6.73917 4.22913 6.5C4.22913 6.26083 4.42746 6.0625 4.66663 6.0625H9.33329C9.57246 6.0625 9.77079 6.26083 9.77079 6.5C9.77079 6.73917 9.57246 6.9375 9.33329 6.9375Z" fill="white" />
            <path d="M7 9.27116C6.76083 9.27116 6.5625 9.07283 6.5625 8.83366V4.16699C6.5625 3.92783 6.76083 3.72949 7 3.72949C7.23917 3.72949 7.4375 3.92783 7.4375 4.16699V8.83366C7.4375 9.07283 7.23917 9.27116 7 9.27116Z" fill="white" />
        </svg>
    ];

    const filterIcon = [
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7_8643)">
                <path d="M5.83333 10.5H8.16667V9.33333H5.83333V10.5ZM1.75 3.5V4.66667H12.25V3.5H1.75ZM3.5 7.58333H10.5V6.41667H3.5V7.58333Z" fill="#3397ef" />
            </g>
            <defs>
                <clipPath id="clip0_7_8643">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ]

    const statusOptions = [
        {
            label: 'Active',
            value: "true"
        },
        {
            label: 'Inactive',
            value: "false"
        }
    ];

    const navigate = useNavigate()

    const [isTabActive, setTabActive] = useState(1)
    const [isUpgrade, setisUpgrade] = useState(false)
    const [isPlancard, setisPlancard] = useState(1)

    const handleTabChange = (tabIndex) => {
        setisPlancard(tabIndex);
    };

    const plusIcone = [
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
            <path d="M7.00002 12.7712C3.54085 12.7712 0.729187 9.95949 0.729187 6.50033C0.729187 3.04116 3.54085 0.229492 7.00002 0.229492C10.4592 0.229492 13.2709 3.04116 13.2709 6.50033C13.2709 9.95949 10.4592 12.7712 7.00002 12.7712ZM7.00002 1.10449C4.02502 1.10449 1.60419 3.52533 1.60419 6.50033C1.60419 9.47533 4.02502 11.8962 7.00002 11.8962C9.97502 11.8962 12.3959 9.47533 12.3959 6.50033C12.3959 3.52533 9.97502 1.10449 7.00002 1.10449Z" fill="white" />
            <path d="M9.33335 6.9375H4.66669C4.42752 6.9375 4.22919 6.73917 4.22919 6.5C4.22919 6.26083 4.42752 6.0625 4.66669 6.0625H9.33335C9.57252 6.0625 9.77085 6.26083 9.77085 6.5C9.77085 6.73917 9.57252 6.9375 9.33335 6.9375Z" fill="white" />
            <path d="M7 9.27116C6.76083 9.27116 6.5625 9.07283 6.5625 8.83366V4.16699C6.5625 3.92783 6.76083 3.72949 7 3.72949C7.23917 3.72949 7.4375 3.92783 7.4375 4.16699V8.83366C7.4375 9.07283 7.23917 9.27116 7 9.27116Z" fill="white" />
        </svg>
    ]

    return (
        <>
            {/* <AdAvailablePlans isActive={isUpgrade} setIsActive={setisUpgrade} /> */}
            <div className='Appliedfinancemain'>
                <div className='flex-between w-100'>
                    <div className="headingwrap">
                        <h2 className='heading-2'>Configurations</h2>
                        <p className="text2 texttitele ">Plans available <strong>3</strong></p>
                    </div>

                    <Button name={'Create Custom Plan'} svg={addsvg} className={'BlueFillButton'} svgIcon={plusIcone} onClick={() => navigate('/create-plan')} />

                </div>

                <div className='ContainerTabForAllTabData'>
                    <div className="MenusTabWrapper w-100 Addsboardmenu">
                        <ul className='flex gap-30 items-center'>
                            <li onClick={() => setTabActive(1)} className={isTabActive === 1 && 'active'}>Plan Details</li>
                            <li onClick={() => setTabActive(2)} className={isTabActive === 2 && 'active'}>Policies</li>
                        </ul>
                    </div>
                    <div className="border-btm w-100"></div>

                    <div className='SpbodyDataForalldata'>
                        <div className={`commonTableWrapper Allpromainwrap Opentab ${isTabActive === 1 && 'active'}`}>
                            <div class="AdscrolltabWrap">
                                <input type="radio" name="tab1" id="tab6" class="AdtabDrop AdDroptab_1" checked={isPlancard === 1} onChange={() => handleTabChange(1)} />
                                <label class="AdtablabelDrop" for="tab6">End of call</label>

                                <input type="radio" name="tab1" id="tab7" class="AdtabDrop AdDroptab_2" checked={isPlancard === 2} onChange={() => handleTabChange(2)} />
                                <label class="AdtablabelDrop" for="tab7">Same day</label>
                                <div class="AdIndicator"></div>
                            </div>

                            <div className={`Opentab ${isPlancard === 1 && 'active'}`}>
                                <div className='PavailabeBoxcard mt-20'>
                                    <AdPlansCard colortag="purple" subtitle="Starter" monthplan="299" subplan={'897'} tag="" btn="Select Plan" />
                                    <AdPlansCard colortag="green" subtitle="Professional" monthplan="299" subplan={'1797'} tag="Popular" className="active" btn="Active Plan" />
                                    <AdPlansCard colortag="yellow" subtitle="Enterprise" monthplan="299" subplan={'2997'} tag="" btn="Select Plan" />
                                </div>

                                <div className='bordergray mt-20'></div>

                                <div className='PavailabeBoxcard mt-20'>
                                    <CustomPlanCard colortag="yellow" subtitle="Customized" monthplan="299" subplan={'2997'} tag="" btn="Select Plan" />
                                </div>
                            </div>

                            <div className={`Opentab ${isPlancard === 2 && 'active'}`}>
                                <div className='PavailabeBoxcard mt-20'>
                                    <AdPlansCard colortag="purple" subtitle="Starter" monthplan="399" subplan={'897'} tag="" btn="Select Plan" />
                                    <AdPlansCard colortag="green" subtitle="Professional" monthplan="299" subplan={'1797'} tag="Popular" className="active" btn="Active Plan" />
                                    <AdPlansCard colortag="yellow" subtitle="Enterprise" monthplan="299" subplan={'2997'} tag="" btn="Select Plan" />
                                </div>
                            </div>

                        </div>


                        <div className={`commonTableWrapper Allpromainwrap Opentab ${isTabActive === 2 && 'active'}`}>
                            <div className={`AdconfigwholeContain mt-20`}>

                                {/* <div className='Adconfigheading'>
                                    <div className="flex-between w-100">
                                        <h2 className="heading-2"> For Admin</h2>
                                    </div>
                                </div> */}

                                <div className='AdconfigBodyBlock fixtdConfig1'>
                                    <div className={`CallHistoryTabledata`}>
                                        <table className='stickytable'>
                                            <thead>
                                                <tr className='headingTabletr'>
                                                    <th>Policy</th>
                                                    <th>Value</th>
                                                    <th>Permissions </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Maximum auditors to join</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'auditor(s)'} className={'GreyBorderButton'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Email trigger on slot exhaust</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'slot(s)'} className={'GreyBorderButton'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Maximum Mentors in a program</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'mentors'} className={'GreyBorderButton'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Mentorship cost fixed for all mentors</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                {'-'}
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Call Link expiry</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox">
                                                            <div class="AdscrolltabWrap">
                                                                <input type="radio" name="Adtab" id="tab22" class="AdtabDrop AdDroptab_1" />
                                                                <label class="AdtablabelDrop" for="tab22">End of call</label>

                                                                <input type="radio" name="Adtab" id="tab23" class="AdtabDrop AdDroptab_2" />
                                                                <label class="AdtablabelDrop" for="tab23">Same day</label>
                                                                <div class="AdIndicator"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Minimum duration for call to mark as Complete</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5 ConfDropdowntd'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <DropdownCustom options={statusOptions} placeholder={'minutes'} src={'/assets/img/arrow-down2.png'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Enable time for Call Join button </h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5 ConfDropdowntd'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <DropdownCustom options={statusOptions} placeholder={'hours'} src={'/assets/img/arrow-down2.png'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Maximum call duration</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'credits'} className={'GreyBorderButton'} />

                                                                <label htmlFor="dateid" className='SelectdatePicker'>
                                                                    <input type="date" id='dateid' />
                                                                </label>
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr >
                                                    <td>
                                                        <div className='tableBox'>
                                                            <div className='policySvgtd'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1786 1.5L10.6667 1.5C10.9428 1.5 11.1667 1.72386 11.1667 2C11.1667 2.27614 10.9428 2.5 10.6667 2.5H5.2C4.6317 2.5 4.23554 2.50039 3.92712 2.52559C3.62454 2.55031 3.45069 2.5964 3.31901 2.66349C3.03677 2.8073 2.8073 3.03677 2.66349 3.31901C2.5964 3.45069 2.55031 3.62454 2.52559 3.92712C2.50039 4.23554 2.5 4.6317 2.5 5.2V10.8C2.5 11.3683 2.50039 11.7645 2.52559 12.0729C2.55031 12.3755 2.5964 12.5493 2.66349 12.681C2.8073 12.9632 3.03677 13.1927 3.31901 13.3365C3.45069 13.4036 3.62454 13.4497 3.92712 13.4744C4.23554 13.4996 4.6317 13.5 5.2 13.5H10.8C11.3683 13.5 11.7645 13.4996 12.0729 13.4744C12.3755 13.4497 12.5493 13.4036 12.681 13.3365C12.9632 13.1927 13.1927 12.9632 13.3365 12.681C13.4036 12.5493 13.4497 12.3755 13.4744 12.0729C13.4996 11.7645 13.5 11.3683 13.5 10.8V8C13.5 7.72386 13.7239 7.5 14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V10.8214C14.5 11.3633 14.5 11.8004 14.4711 12.1543C14.4413 12.5187 14.3784 12.8388 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.8388 14.3784 12.5187 14.4413 12.1543 14.4711C11.8004 14.5 11.3633 14.5 10.8214 14.5H5.17858C4.6367 14.5 4.19962 14.5 3.84569 14.4711C3.48126 14.4413 3.16117 14.3784 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.62159 12.8388 1.55868 12.5187 1.52891 12.1543C1.49999 11.8004 1.5 11.3633 1.5 10.8214V5.1786C1.5 4.6367 1.49999 4.19963 1.52891 3.84569C1.55868 3.48126 1.62159 3.16117 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.16117 1.62159 3.48126 1.55868 3.84569 1.52891C4.19963 1.49999 4.6367 1.5 5.1786 1.5ZM15.0202 2.31311C15.2155 2.50838 15.2155 2.82496 15.0202 3.02022L8.35355 9.68689C8.15829 9.88215 7.84171 9.88215 7.64645 9.68689L5.64645 7.68689C5.45118 7.49162 5.45118 7.17504 5.64645 6.97978C5.84171 6.78452 6.15829 6.78452 6.35355 6.97978L8 8.62623L14.3131 2.31311C14.5084 2.11785 14.825 2.11785 15.0202 2.31311Z" fill="#737373" />
                                                                </svg>
                                                                <h4>Maximum auditors to join</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="tableBox slotBtnpd">
                                                            <h4 className='flex gap-5'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'Call(s)'} className={'GreyBorderButton'} />
                                                            </h4>
                                                            <h4 className='flex gap-5 ml-10'>
                                                                <label className='Mnccount btntaggray' htmlFor='num' >
                                                                    <input type="number" min={0} id='num' />
                                                                </label>
                                                                <Button name={'credit(s) per call'} className={'GreyBorderButton'} />
                                                            </h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='tableBox'>
                                                            <ToggleSwitch />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        <div className="ViewMoreButton">
                                            <Button name={'Load More'} className={'BlueFillButton buttontext'} />
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Configure