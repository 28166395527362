import React from 'react'
import './DatanotFoundStyle.css'

const DatanotFound = () => {
    return (
        <tr className='DataNotFoundRow'>
        <td colspan="100%">
            <div className='dataNotfoundwrapper'>
                <img src="/assets/img/gif/datanotfound.gif" alt="datanotfound" />
                <div className="nodatafound">
                    <h3 className='h3datatext'>No data found</h3>
                    <p className='text2'>We couldn’t find what you searched for.
                        Please try again later.</p>
                </div>
            </div>
        </td>
    </tr>
    )
}

export default DatanotFound