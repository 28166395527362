import React from 'react'
import './DeletepoupStyle.css'
import Button from '../../Button/Button'

const Deletepoup = ({ isActive, setIsActive, handleClose, handleDelete, text }) => {
    return (
        <div className={`popupWrapper poupviewtab ${isActive && "active"}`}>
            <div className="popupContainer deleteIconpoup">
                <div className="popupBody mb-0">
                    <div className="datadelete bgdeleteback">
                        <img src="/assets/img/gif/delete.gif" alt="delete_icon" onClick={handleClose} />
                        <h3 className='headingpouptext'>Are you sure you want to delete {text ? text : 'this data'} ?</h3>
                    </div>
                </div>
                <div className="popupheadercenter gap-10 mt-30">
                    <Button name={'Discard'} transparent={true} className={'btnnormal'} onClick={handleClose} />
                    <Button name={'Delete'} noBorder className={"button active buttontext BlueFillButton "} onClick={handleDelete} />
                </div>
            </div>
        </div>
    )
}

export default Deletepoup