import React, { useEffect, useState } from 'react'
import './ViewCallDetailsStyle.css'
// import CallRecordpopup from '../../CallRecordpopup/CallRecordpopup'
import Progressbar from '../../ProgressBar/Progressbar';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import moment from 'moment-timezone';

const ViewCallDetails = ({ isActive, handleClose, callInfo, menteeFeedback, mentorFeedback, postSessionMentor, preSessionMentee, postSessionMentee }) => {

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const [optionAnswers, setOptionsAnswers] = useState([]);

    const navigate = useNavigate();
    const [fbackopen, setfbackopen] = useState(1);
    const [openTab, setOpenTab] = useState(0);

    useEffect(() => {
        mentorFeedback?.feedbackQuestions?.forEach(question => {
            question?.feedbackAns?.forEach(option => {
                setOptionsAnswers(prev => [...prev, ...(option?.feedbackOptionId?.split("|") || [])]);
            });
        });
        menteeFeedback?.feedbackQuestions?.forEach(question => {
            question?.feedbackAns?.forEach(option => {
                setOptionsAnswers(prev => [...prev, ...(option?.feedbackOptionId?.split("|") || [])]);
            });
        });
    }, [mentorFeedback, menteeFeedback]);

    return (
        <div className={`filterPopupPastcall CallDetailsUserpopupmain ${isActive && 'active'}`}>
            <div className={`PopupFilterCallWrapper`}>
                <div className="FilterBodyPasscall calldspopupSuperAdmin">
                    <div className="popupHeader">
                        <span className="close"><img src="/assets/svg/close.svg" alt="" onClick={handleClose} /></span>
                    </div>
                    <div className="headingwrapper">
                        <h3>Call Details</h3>
                    </div>
                    <div className="ContentBodyWrapperPastCall">
                        <div className="HeaderCallDetailsFaq">
                            <p className='text2'> <span className=''>Agenda:</span>
                                {callInfo?.interviewAgenda?.agenda ? callInfo?.interviewAgenda?.agenda : callInfo?.agenda}
                            </p>
                        </div>
                        <div className='AgendapopupCallhistory'>
                            <div className='AnswerFaq  AnsForNewFaqUsers'>
                                <div className="answerFaqnew">
                                    <ul>
                                        <li>
                                            <div className="profileHistoryDeatils f-items-center">
                                                <div className="ImaUserhistory">
                                                    <img src={callInfo?.mentor?.profileImg ? callInfo?.mentor?.profileImg : "/assets/img/User.png"} alt="" className='Userimg pointer' onClick={() => { navigate(`/admin/mentorprofile/${callInfo?.mentor?.organizationId}/${callInfo?.mentor?.id}`) }} />
                                                </div>
                                                <div className="CallDetailsNameData flex">
                                                    <div className="NameDetails">
                                                        <h3 className='namelink pointer' onClick={() => navigate(`/mentorprofile/${callInfo?.mentor?.organizationId}/${callInfo?.mentor?.id}`)}>{callInfo?.mentor?.firstName ? `${callInfo?.mentor?.firstName} ${callInfo?.mentor?.lastName}` : "---"}</h3>
                                                        <p className="text2 capitilize">{callInfo?.mentor?.profession?.designation ? callInfo?.mentor?.profession?.designation : "---"}</p>
                                                    </div>
                                                    <span className='Mentorspan Designspan'>Mentor</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li> <span>Date:</span>{callInfo?.startTime ? moment(callInfo?.startTime).subtract(5, "hours").subtract(30, "minutes").tz(Intl.DateTimeFormat().resolvedOptions().timeZone)?.format("DD MMMM, YYYY") : "---"}</li>
                                        {callInfo?.meetingStatus === "completed" && <>
                                            <li> <span>Call Start time:</span>{callInfo?.startedAt ? moment(callInfo?.startedAt).format("h:mm a") : "---"}</li>
                                            <li> <span>Call Started by: </span>{callInfo?.startedBy ? callInfo?.startedBy : "---"}</li>
                                            <li> <span>Call Duration: </span>{callInfo?.callDuration ? `${(callInfo?.callDuration / 60).toFixed(0)} min` : "---"}</li>
                                        </>}
                                        {(callInfo?.meetingStatus === "cancelled" || callInfo?.meetingStatus === "rescheduled") &&
                                            <li><span>Reason: </span>{callInfo?.cancelReason}</li>
                                        }
                                        {callInfo?.meetingStatus === "pending" &&
                                            <li><span className='capitilize'>Mentee: </span>{callInfo?.isMenteeJoined ? "Joined" : "No Show"}</li>
                                        }
                                    </ul>
                                    <ul>
                                        <li>
                                            <div className="profileHistoryDeatils f-items-center">
                                                <div className="ImaUserhistory">
                                                    <img src={callInfo?.mentee?.profileImg ? callInfo?.mentee?.profileImg : "/assets/img/User.png"} alt="" className=' pointer' onClick={() => { navigate('/menteesprofile') }} />
                                                </div>
                                                <div className="CallDetailsNameData flex">
                                                    <div className="NameDetails">
                                                        <h3 className='namelink pointer' onClick={() => { navigate(`/menteeprofile/${callInfo?.mentee?.organizationId}/${callInfo?.mentee?.id}`) }}>
                                                            {callInfo?.mentee?.firstName ? `${callInfo?.mentee?.firstName} ${callInfo?.mentee?.lastName}` : "---"}
                                                        </h3>
                                                        <p className="text2 capitilize">{callInfo?.mentee?.profession?.title ? callInfo?.mentee?.profession?.title === "Work" ? 'Working Professional' : 'Student' : "---"}</p>
                                                    </div>
                                                    <span className='Menteespan Designspan'>Mentee</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li> <span>Programme: </span>{callInfo?.program?.programName ? callInfo?.program?.programName : "---"}</li>
                                        {callInfo?.meetingStatus === "completed" && <>
                                            <li> <span>Call End time:</span>{callInfo?.endedAt ? moment(callInfo?.endedAt).format("h:mm a") : "---"}</li>
                                            <li> <span>Call Ended by:</span>{callInfo?.endedBy ? callInfo?.endedBy : "---"}</li>
                                        </>}
                                        {(callInfo?.meetingStatus === "cancelled" || callInfo?.meetingStatus === "rescheduled") &&
                                            <li><span className='capitilize'>{callInfo?.meetingStatus} by: </span>{callInfo?.cancelledBy ? callInfo?.cancelledBy : "---"}</li>

                                        }
                                        {callInfo?.meetingStatus === "pending" &&
                                            <li><span className='capitilize'>Mentor: </span>{callInfo?.isMentorJoined ? "Joined" : "No Show"}</li>
                                        }
                                    </ul>
                                </div>
                                {(callInfo?.meetingStatus === "completed" || callInfo?.meetingStatus === "pending") && <>
                                    <div className="headercontentfaq flex-between mt-20">
                                        <div className="btnRightsidesale pillButtons">
                                            <Button name={'Mentee'} className={fbackopen === 1 && 'active'} onClick={() => setfbackopen(1)} />
                                            <Button name={'Mentor'} className={fbackopen === 2 && 'active'} onClick={() => setfbackopen(2)} />
                                        </div>
                                    </div>
                                    <div className={`Fbackactivetab mt-30 ${fbackopen === 1 && 'active'}`}>
                                        <div className='GnFbackackcontain'>
                                            <div className={`GnfaqfbackWar ${openTab === 1 && 'active'}`}>
                                                <div className="GnHeadingFaq" onClick={() => openTab === 1 ? setOpenTab(0) : setOpenTab(1)}>
                                                    <h3 className='text14'>Pre-Session Details</h3>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10225 6.63643C4.32192 6.41676 4.67808 6.41676 4.89775 6.63643L9 10.7387L13.1023 6.63643C13.3219 6.41676 13.6781 6.41676 13.8977 6.63643C14.1174 6.8561 14.1174 7.21226 13.8977 7.43193L9.39775 11.9319C9.17808 12.1516 8.82192 12.1516 8.60225 11.9319L4.10225 7.43193C3.88258 7.21226 3.88258 6.8561 4.10225 6.63643Z" fill="black" />
                                                    </svg>
                                                </div>

                                                <div className="GnBodyFaq mt-16">
                                                    {preSessionMentee?.length > 0 ? preSessionMentee?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <h3 className='text14 mt-20'>{item?.question ? item?.question : "---"}</h3>
                                                            <ol className="GnlistWrapfaq mt-11">
                                                                {item?.sessionAns?.map((answer, i) => (
                                                                    <li className='text13'><span>{i + 1}.</span> {answer?.answer ? answer?.answer : "---"}</li>
                                                                ))}
                                                            </ol>
                                                        </React.Fragment>
                                                    )) : "No Data Available"}
                                                </div>
                                            </div>

                                            <div className={`GnfaqfbackWar ${openTab === 2 && 'active'}`}>
                                                <div className="GnHeadingFaq" onClick={() => openTab === 2 ? setOpenTab(0) : setOpenTab(2)}>
                                                    <h3 className='text14'>Post-Session Details</h3>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10225 6.63643C4.32192 6.41676 4.67808 6.41676 4.89775 6.63643L9 10.7387L13.1023 6.63643C13.3219 6.41676 13.6781 6.41676 13.8977 6.63643C14.1174 6.8561 14.1174 7.21226 13.8977 7.43193L9.39775 11.9319C9.17808 12.1516 8.82192 12.1516 8.60225 11.9319L4.10225 7.43193C3.88258 7.21226 3.88258 6.8561 4.10225 6.63643Z" fill="black" />
                                                    </svg>
                                                </div>

                                                <div className="GnBodyFaq mt-16">
                                                    {postSessionMentee?.filter(item => !item?.isTasks)?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <h3 className='text14'>{item?.question ? item?.question : "---"}</h3>
                                                            <ol className="GnlistWrapfaq mt-11">
                                                                {item?.sessionAns?.map((answer, i) => (
                                                                    <li className='text13'><span>{i + 1}.</span> {answer?.answer ? answer?.answer : "---"}</li>
                                                                ))}
                                                            </ol>
                                                        </React.Fragment>
                                                    ))}

                                                    {postSessionMentee?.filter(item => item?.isTasks)?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <h3 className='text14 mt-32'>{item?.question ? item?.question : "---"}</h3>
                                                            <div className="timplineGnlWrap mt-11">
                                                                {item?.sessionAns?.map((answer, i) => (
                                                                    <label className="AdGnpopupFaqcheck MnCrlabelwrap" key={i}>
                                                                        <input type="checkbox" disabled checked={answer?.isComplete} />
                                                                        <span className="checkmark"></span>
                                                                        <p className='text13'>{answer?.answer ? answer?.answer : "---"}</p>
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                    {postSessionMentee?.length === 0 && 'No Data Available'}
                                                </div>
                                            </div>

                                            <div className={`GnfaqfbackWar ${openTab === 3 && 'active'}`}>
                                                <div className="GnHeadingFaq" onClick={() => openTab === 3 ? setOpenTab(0) : setOpenTab(3)}>
                                                    <h3 className='text14'>Feedback Given</h3>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10225 6.63643C4.32192 6.41676 4.67808 6.41676 4.89775 6.63643L9 10.7387L13.1023 6.63643C13.3219 6.41676 13.6781 6.41676 13.8977 6.63643C14.1174 6.8561 14.1174 7.21226 13.8977 7.43193L9.39775 11.9319C9.17808 12.1516 8.82192 12.1516 8.60225 11.9319L4.10225 7.43193C3.88258 7.21226 3.88258 6.8561 4.10225 6.63643Z" fill="black" />
                                                    </svg>
                                                </div>

                                                <div className="GnBodyFaq">

                                                    {menteeFeedback && Object?.keys(menteeFeedback).length > 0 ? <div className={`ProgressQuestionCallhistory`}>

                                                        {menteeFeedback?.feedbackQuestions?.map((item, index) => (
                                                            <div className="QuestionProgressbarPop" key={index}>
                                                                <h3 className='fw-500'>{item?.title ? item?.title : "---"}</h3>
                                                                {item?.description && <p className='mb-5' style={{ fontSize: "12px" }}>({item?.description ? item?.description : "---"})</p>}
                                                                {(item?.type === "MultiSelect" || item?.type === "SingleSelect") ?
                                                                    <div class="profileData AdAnssetwidth mt-20">
                                                                        <div class="dataexpertise">
                                                                            {item?.feedbackQuestionOptions?.map((option, i) => (
                                                                                <div class={`text2 ${optionAnswers?.includes(option?.id) && 'divactive'}`} key={i}>{option?.value ? option?.value : "---"}</div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    item?.type === "Scale" ?
                                                                        item?.feedbackAns?.length > 0 ? <Progressbar answer={item?.feedbackAns[0]?.answer} count={item?.count ?? 5} className={+item?.feedbackAns[0]?.answer > 2 ? 'green' : "yellow"} /> : "---"
                                                                        :
                                                                        item?.type === "Rating" ?
                                                                            item?.feedbackAns?.length > 0 ? <div class="profileData AdAnssetwidth mt-20">
                                                                                <div className='ratingStarswrap'>
                                                                                    {numbers?.slice(0, (+item?.count))?.map(num => (
                                                                                        <span className={`${num <= (+item?.feedbackAns[0]?.answer) && 'active'}`} key={num}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 28 28" fill="none">
                                                                                                <path d="M13.2677 15.6595C13.1556 15.6599 13.0449 15.6335 12.9451 15.5823L9.36741 13.7093L5.78976 15.5823C5.67359 15.6434 5.54261 15.6707 5.41171 15.661C5.28081 15.6514 5.15524 15.6052 5.04928 15.5277C4.94332 15.4503 4.86122 15.3446 4.8123 15.2228C4.76339 15.101 4.74963 14.968 4.77259 14.8387L5.47409 10.8893L2.58391 8.0833C2.49374 7.99332 2.42977 7.88048 2.39887 7.75689C2.36798 7.6333 2.37132 7.50363 2.40854 7.3818C2.44919 7.25713 2.52399 7.14635 2.62442 7.06203C2.72486 6.97771 2.84692 6.92324 2.97675 6.90478L6.9753 6.32254L8.73606 2.72385C8.7935 2.60524 8.88319 2.50522 8.99485 2.43523C9.10651 2.36524 9.23563 2.32812 9.36741 2.32812C9.49919 2.32812 9.6283 2.36524 9.73997 2.43523C9.85163 2.50522 9.94131 2.60524 9.99876 2.72385L11.7806 6.31552L15.7791 6.89777C15.9089 6.91622 16.031 6.9707 16.1314 7.05501C16.2319 7.13933 16.3067 7.25011 16.3473 7.37479C16.3845 7.49662 16.3879 7.62629 16.357 7.74987C16.3261 7.87346 16.2621 7.9863 16.1719 8.07628L13.2818 10.8823L13.9833 14.8317C14.0083 14.9632 13.9952 15.0992 13.9455 15.2235C13.8958 15.3478 13.8115 15.4553 13.7027 15.5332C13.5756 15.6222 13.4227 15.6666 13.2677 15.6595Z" fill="#3397EF" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    ))}
                                                                                </div>
                                                                            </div> : "---"
                                                                            :
                                                                            item?.feedbackAns?.length > 0 ? <p className="text2 capitilize">{item?.feedbackAns[0]?.answer ? item?.feedbackAns[0]?.answer : "---"}</p> : "---"}
                                                            </div>
                                                        ))}
                                                    </div> : "No Feedback Given"}
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={`Fbackactivetab mt-30 ${fbackopen === 2 && 'active'}`}>
                                        <div className='GnFbackackcontain'>

                                            <div className={`GnfaqfbackWar ${openTab === 5 && 'active'}`}>
                                                <div className="GnHeadingFaq" onClick={() => openTab === 5 ? setOpenTab(0) : setOpenTab(5)}>
                                                    <h3 className='text14'>Post-Session Details</h3>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10225 6.63643C4.32192 6.41676 4.67808 6.41676 4.89775 6.63643L9 10.7387L13.1023 6.63643C13.3219 6.41676 13.6781 6.41676 13.8977 6.63643C14.1174 6.8561 14.1174 7.21226 13.8977 7.43193L9.39775 11.9319C9.17808 12.1516 8.82192 12.1516 8.60225 11.9319L4.10225 7.43193C3.88258 7.21226 3.88258 6.8561 4.10225 6.63643Z" fill="black" />
                                                    </svg>
                                                </div>

                                                <div className="GnBodyFaq mt-16">
                                                    {postSessionMentor?.length > 0 ? postSessionMentor?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <h3 className='text14 mt-20'>{item?.question ? item?.question : "---"}</h3>
                                                            <ol className="GnlistWrapfaq mt-11">
                                                                {item?.sessionAns?.map((answer, i) => (
                                                                    <li className='text13'><span>{i + 1}.</span> {answer?.answer ? answer?.answer : "---"}</li>
                                                                ))}
                                                            </ol>
                                                        </React.Fragment>
                                                    )) : "No Remarks Recevied"}
                                                </div>
                                            </div>

                                            <div className={`GnfaqfbackWar ${openTab === 6 && 'active'}`}>
                                                <div className="GnHeadingFaq" onClick={() => openTab === 6 ? setOpenTab(0) : setOpenTab(6)}>
                                                    <h3 className='text14'>Feedback Given</h3>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10225 6.63643C4.32192 6.41676 4.67808 6.41676 4.89775 6.63643L9 10.7387L13.1023 6.63643C13.3219 6.41676 13.6781 6.41676 13.8977 6.63643C14.1174 6.8561 14.1174 7.21226 13.8977 7.43193L9.39775 11.9319C9.17808 12.1516 8.82192 12.1516 8.60225 11.9319L4.10225 7.43193C3.88258 7.21226 3.88258 6.8561 4.10225 6.63643Z" fill="black" />
                                                    </svg>
                                                </div>

                                                <div className="GnBodyFaq">
                                                    {mentorFeedback && Object?.keys(mentorFeedback).length > 0 ? <div className={`ProgressQuestionCallhistory`}>

                                                        {mentorFeedback?.feedbackQuestions?.map((item, index) => (
                                                            <div className="QuestionProgressbarPop" key={index}>
                                                                <h3 className='fw-500'>{item?.title ? item?.title : "---"}</h3>
                                                                {item?.description && <p className='mb-5' style={{ fontSize: "12px" }}>({item?.description ? item?.description : "---"})</p>}
                                                                {(item?.type === "MultiSelect" || item?.type === "SingleSelect") ?
                                                                    <div class="profileData AdAnssetwidth mt-20">
                                                                        <div class="dataexpertise">
                                                                            {item?.feedbackQuestionOptions?.map((option, i) => (
                                                                                <div class={`text2 ${optionAnswers?.includes(option?.id) && 'divactive'}`} key={i}>{option?.value ? option?.value : "---"}</div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    item?.type === "Scale" ?
                                                                        item?.feedbackAns?.length > 0 ? <Progressbar answer={item?.feedbackAns[0]?.answer} count={item?.count ?? 5} className={+item?.feedbackAns[0]?.answer > 2 ? 'green' : "yellow"} /> : "---"
                                                                        :
                                                                        item?.type === "Rating" ?
                                                                            item?.feedbackAns?.length > 0 ? <div class="profileData AdAnssetwidth mt-20">
                                                                                <div className='ratingStarswrap'>
                                                                                    {numbers?.slice(0, (+item?.count))?.map(num => (
                                                                                        <span className={`${num <= (+item?.feedbackAns[0]?.answer) && 'active'}`} key={num}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 28 28" fill="none">
                                                                                                <path d="M13.2677 15.6595C13.1556 15.6599 13.0449 15.6335 12.9451 15.5823L9.36741 13.7093L5.78976 15.5823C5.67359 15.6434 5.54261 15.6707 5.41171 15.661C5.28081 15.6514 5.15524 15.6052 5.04928 15.5277C4.94332 15.4503 4.86122 15.3446 4.8123 15.2228C4.76339 15.101 4.74963 14.968 4.77259 14.8387L5.47409 10.8893L2.58391 8.0833C2.49374 7.99332 2.42977 7.88048 2.39887 7.75689C2.36798 7.6333 2.37132 7.50363 2.40854 7.3818C2.44919 7.25713 2.52399 7.14635 2.62442 7.06203C2.72486 6.97771 2.84692 6.92324 2.97675 6.90478L6.9753 6.32254L8.73606 2.72385C8.7935 2.60524 8.88319 2.50522 8.99485 2.43523C9.10651 2.36524 9.23563 2.32812 9.36741 2.32812C9.49919 2.32812 9.6283 2.36524 9.73997 2.43523C9.85163 2.50522 9.94131 2.60524 9.99876 2.72385L11.7806 6.31552L15.7791 6.89777C15.9089 6.91622 16.031 6.9707 16.1314 7.05501C16.2319 7.13933 16.3067 7.25011 16.3473 7.37479C16.3845 7.49662 16.3879 7.62629 16.357 7.74987C16.3261 7.87346 16.2621 7.9863 16.1719 8.07628L13.2818 10.8823L13.9833 14.8317C14.0083 14.9632 13.9952 15.0992 13.9455 15.2235C13.8958 15.3478 13.8115 15.4553 13.7027 15.5332C13.5756 15.6222 13.4227 15.6666 13.2677 15.6595Z" fill="#3397EF" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    ))}
                                                                                </div>
                                                                            </div> : "---"
                                                                            :
                                                                            item?.feedbackAns?.length > 0 ? <p className="text2 capitilize">{item?.feedbackAns[0]?.answer ? item?.feedbackAns[0]?.answer : "---"}</p> : "---"}
                                                            </div>
                                                        ))}
                                                    </div> : "No Feedback Given"}

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </>}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCallDetails