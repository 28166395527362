import React from "react";
import Chart from "react-apexcharts";

const Overview = ({ isActive }) => {

  const donutValues = [260, 21, 2, 13];

  const data = {
    series: donutValues,
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#7BC896", "#70B6F4", "#EF5350", "#E5E5E5"],
    },
  };

  const data2 = {
    series: [
      {
        data: [441, 355, 357, 356, 361, 358],
      },
      {
        data: [576, 585, 510, 598, 587, 405],
      },
    ],
    options: {
        // toolbar: {
        //     show: false
        // },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "18%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      },

      colors: ["#70B6F4", "#E5E5E5"],
    },
  };

  return (
    <div className={`SaleorActivityWrapper tabWrapper ${isActive && "active"}`}>
      <div className="SalesPerformanceWrapper">
        <div className="LeftSideSales border-btm">
          <div className="headingUserbtm">
            <h2 className="heading-2  mb-5">Calls Summary</h2>
            <p className="text2">21 Upcoming</p>
          </div>
        </div>
        <div className="CallOverTimeContent">
          <div className="GraphCirclewrapper">
            <Chart
              series={data.series}
              options={data.options}
              width="100%"
              type="donut"
            />
            <div className="contentInGraph">
              <h3>331</h3>
              <p className="text2">Total Calls</p>
            </div>
          </div>
          <div className="GraphCircleDataarea">
            <table>
              {donutValues.map((item, index) => (
                <tr key={index}>
                  <th>
                    <div className={`${!index ? "completed" : index === 1 ? "upcoming" : index === 2 ? "cancelled" : "pending"} f-items-center gap-10`}>
                      <span className="listicon"></span> {!index ? "Completed" : index === 1 ? "Upcoming" : index === 2 ? "Cancelled" : "Yet to Start"}
                    </div>
                  </th>
                  <td>{item}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <div className="ActivitiesWrapper">
        <div className="LeftSideSales border-btm">
          <div className="headingUserbtm">
            <h2 className="heading-2  mb-5">Calls Over Time</h2>
            <div className="callOvertime f-items-center gap-10">
              <div className="completed f-items-center gap-10">
                <span className="listicon"></span>
                Completed
              </div>
              <div className="pending f-items-center gap-10">
                <span className="listicon"></span>
                Pending
              </div>
            </div>
          </div>
        </div>
        <div className="CallOverTimeCOntent">
          <Chart
            series={data2.series}
            options={data2.options}
            width="100%"
            height="318px"
            type="bar"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
