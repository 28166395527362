import React from 'react';
import './SlotCardStyles.css';
function SlotCard({ className, day, date, id, name, slotCount, onClick }) {
    return (
        <label className={`SlotCardWrapperBox`} htmlFor={id} onClick={onClick} >
            <input type="radio" name={name} id={id} />
            <div className="SlotCardWrapper">
                <div className="bodySlotarea">
                    <span className='Weekdays'>{day}</span>
                    <h4>{date}</h4>
                </div>
                <div className={`FooterSlotCard ${className}`} >
                    {slotCount ? slotCount : 0} slot{slotCount > 1 ? "s" : ""}
                </div>
            </div>
        </label>
    )
}

export default SlotCard