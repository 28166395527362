import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environments";

const apiRoute = "/api/superAdmin/";

let token = "";
let adminId = "";

const setTokenValues = () => {
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};
export const saveOrgCss = createAsyncThunk(
  "superAdmin/saveOrgCss",
  async ({ css, orgId }, thunkAPI) => {
    try {
      const response = await fetch(
        `${environment.baseURL}${apiRoute}${orgId}/saveOrgCSS`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ css }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const getAllOrganizations = createAsyncThunk(
  "superAdmin/getAllOrganizations",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + "getAllOrganizations",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getOrganizationDetails = createAsyncThunk(
  "superAdmin/getOrganizationDetails",
  async (orgId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + orgId + "/getOrganizationDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllPrograms = createAsyncThunk(
  "superAdmin/getAllPrograms",
  async (orgId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + orgId + "/getAllPrograms",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllAdmins = createAsyncThunk(
  "superAdmin/getAllAdmins",
  async ({ orgId, index }, thunkAPI) => {
    try {
      setTokenValues();
      const page = index ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/getAllUsers/" +
          'admin' +
          `?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllMentors = createAsyncThunk(
  "superAdmin/getAllMentors",
  async ({ orgId, search, program, callDetails, slotDetails, rating, index, sortedBy, sortedOrder }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const pid = program ?? "";
      const cd = callDetails ?? "";
      const sd = slotDetails ?? "";
      const rt = rating ?? "";
      const page = index ?? "";

      const sort = sortedBy ?? "";
      const order = sortedOrder ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/getAllUsers/" +
          'mentor' +
          `?title=${title}&pid=${pid}&cd=${cd}&sd=${sd}&rt=${rt}&page=${page}&sort=${sort}&order=${order}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllMentees = createAsyncThunk(
  "superAdmin/getAllMentees",
  async ({ orgId, search, program, cohort, callDetails, status, index, sortedBy, sortedOrder }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const pid = program ?? "";
      const ct = cohort ?? "";
      const cd = callDetails ?? "";
      const active = status ?? "";
      const page = index ?? "";

      const sort = sortedBy ?? "";
      const order = sortedOrder ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/getAllUsers/" +
          'mentee' +
          `?title=${title}&pid=${pid}&ct=${ct}&ch=${cd}&active=${active}&page=${page}&sort=${sort}&order=${order}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllUsersCsvDownload = createAsyncThunk(
  "admin/getAllUsersCsvDownload",
  async ({ orgId, user, search, program, cohort, callDetails, status, slotDetails, rating, sortedBy, sortedOrder }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const programId = program ?? "";
      const ct = cohort ?? "";
      const cd = callDetails ?? "";
      const sd = slotDetails ?? "";
      const rt = rating ?? "";
      const active = status ?? "";

      const sort = sortedBy ?? "";
      const order = sortedOrder ?? "";

      const response = await fetch(
        environment.baseURL +
        apiRoute +
        orgId +
        `/getAllUsersCsvDownload/${user}?title=${title}&pid=${programId}&ct=${ct}&ch=${cd}&active=${active}&sort=${sort}&order=${order}&sd=${sd}&rt=${rt}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }

      if (response.status === 200) {
        const csvData = await response.text();

        // Create a Blob object from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';

        // Trigger the download
        link.click();

        // Clean up by revoking the Blob URL
        window.URL.revokeObjectURL(url);

        return csvData;
      } else {
        return thunkAPI.rejectWithValue("ERROR");
      }
    } catch (e) {
      console.log("error downloading csv :", e)
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllUpcomingCalls = createAsyncThunk(
  "admin/getAllUpcomingCalls",
  async ({ orgId, search, date, program, index }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const ate = date ?? "";
      const pid = program ?? "";
      const page = index ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          `/getAllUpcomingCalls?title=${title}&pid=${pid}&ate=${ate}&page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllPastCalls = createAsyncThunk(
  "admin/getAllPastCalls",
  async ({ orgId, search, date, program, status, index }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const ate = date ?? "";
      const pid = program ?? "";
      const ss = status ?? "";
      const page = index ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          `/getAllPastCalls?title=${title}&pid=${pid}&ate=${ate}&ss=${ss}&page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUpcomingInterviewDetails = createAsyncThunk(
  "mentor/getUpcomingInterviewDetails",
  async ({ orgId, userId, user, index }, thunkAPI) => {
    try {
      setTokenValues();

      const page = index ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/" +
          userId +
          "/getUpcomingInterviewDetails/" +
          user +
          `?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPastInterviewDetails = createAsyncThunk(
  "mentor/getPastInterviewDetails",
  async ({ orgId, userId, user, index }, thunkAPI) => {
    try {
      setTokenValues();

      const page = index ?? "";

      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/" +
          userId +
          "/getPastInterviewDetails/" +
          user +
          `?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularUser = createAsyncThunk(
  "superAdmin/getParticularUser",
  async ({ orgId, user, userId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/getParticularUser/" +
          user +
          "/" +
          userId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getSuperAdminDetails = createAsyncThunk(
  "superAdmin/getSuperAdminDetails",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + adminId + "/getSuperAdminDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createOrganization = createAsyncThunk(
  "superAdmin/createOrganization",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + "/createOrganization/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "superAdmin/addUser",
  async ({ user, orgId, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + orgId + "/addUser/" + user,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFeedbackAnswers = createAsyncThunk(
  "superAdmin/getFeedbackAnswers",
  async (bookingId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          apiRoute +
          "booking/" +
          bookingId +
          "/getFeedbackAnswers",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFeedbackWithAnswers = createAsyncThunk(
  "admin/getFeedbackWithAnswers",
  async (bookingId, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
        apiRoute +
        bookingId +
        `/getFeedbackWithAnswers`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          }
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getMentorAvailableDates = createAsyncThunk(
  "admin/getMentorAvailableDates",
  async ({ orgId, userId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/" +
          userId +
          "/getMentorAvailableDates",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getMentorSlotsByDate = createAsyncThunk(
  "admin/getMentorSlotsByDate",
  async ({ orgId, userId, date }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          apiRoute +
          orgId +
          "/" +
          userId +
          "/getMentorSlotsByDate/" +
          date,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "superAdmin/deleteUser",
  async ({ orgId, userId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + apiRoute + orgId + "/" + userId + "/deleteUser",
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const isSubdomainExists = createAsyncThunk(
  "superAdmin/isSubdomainExists",
  async (orgName, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL + apiRoute + `isSubdomainExists/${orgName}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data?.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateSpecificUser = createAsyncThunk(
  "superAdmin/updateSpecificUser",
  async ({ orgId, userId, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        orgId +
        "/" +
        userId +
        "/updateSpecificUser",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify(obj)
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState: {
    isLoaderTrue: false,
    isFetching: false,
    fetchingAdmins: false,
    fetchingMentors: false,
    fetchingMentees: false,
    isOrgDetailsFetching: false,
    isSuccess: false,
    csvDownloaded: false,
    isUserAddedSuccess: false,
    isError: false,
    errorMessage: "",
    allOrganizations: [],
    organizationDetail: [],
    allPrograms: [],
    allAdmins: [],
    allMentors: [],
    allMentees: [],
    superAdmin: [],
    orgUpcomingCalls: [],
    orgPastCalls: [],
    userUpcomingCalls: [],
    userPastCalls: [],
    particularUser: [],
    feedbackAnswers: [],
    mentorSlotDates: [],
    mentorSlots: [],
    feedbackWithAnswers: [],
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.csvDownloaded = false;
      state.isLoaderTrue = false;
      state.isFetching = false;
      state.fetchingAdmins = false;
      state.fetchingMentors = false;
      state.fetchingMentees = false;
      state.errorMessage = "";
      state.isOrgDetailsFetching = false;
      state.isUserAddedSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrganizations.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAllOrganizations.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      console.log("payload", payload)
      state.allOrganizations = payload;
    });
    builder.addCase(getAllOrganizations.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getOrganizationDetails.pending, (state) => {
      state.isOrgDetailsFetching = true;
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, { payload }) => {
      state.isOrgDetailsFetching = false;
      state.organizationDetail = payload;
    });
    builder.addCase(getOrganizationDetails.rejected, (state, { payload }) => {
      state.isOrgDetailsFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllPrograms.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAllPrograms.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.allPrograms = payload;
    });
    builder.addCase(getAllPrograms.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllAdmins.pending, (state) => {
      state.fetchingAdmins = true;
    });
    builder.addCase(getAllAdmins.fulfilled, (state, { payload }) => {
      state.fetchingAdmins = false;
      state.allAdmins = payload;
    });
    builder.addCase(getAllAdmins.rejected, (state, { payload }) => {
      state.fetchingAdmins = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllMentors.pending, (state) => {
      state.fetchingMentors = true;
    });
    builder.addCase(getAllMentors.fulfilled, (state, { payload }) => {
      state.fetchingMentors = false;
      state.allMentors = payload;
    });
    builder.addCase(getAllMentors.rejected, (state, { payload }) => {
      state.fetchingMentors = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllMentees.pending, (state) => {
      state.fetchingMentees = true;
    });
    builder.addCase(getAllMentees.fulfilled, (state, { payload }) => {
      state.fetchingMentees = false;
      state.allMentees = payload;
    });
    builder.addCase(getAllMentees.rejected, (state, { payload }) => {
      state.fetchingMentees = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getMentorAvailableDates.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getMentorAvailableDates.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.mentorSlotDates = payload;
    });
    builder.addCase(getMentorAvailableDates.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });

    builder.addCase(getMentorSlotsByDate.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getMentorSlotsByDate.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.mentorSlots = payload;
    });
    builder.addCase(getMentorSlotsByDate.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });

    builder.addCase(getAllUpcomingCalls.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAllUpcomingCalls.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.orgUpcomingCalls = payload;
    });
    builder.addCase(getAllUpcomingCalls.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllPastCalls.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAllPastCalls.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.orgPastCalls = payload;
    });
    builder.addCase(getAllPastCalls.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getUpcomingInterviewDetails.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(
      getUpcomingInterviewDetails.fulfilled,
      (state, { payload }) => {
        state.isFetching = false;
        state.userUpcomingCalls = payload;
      }
    );
    builder.addCase(
      getUpcomingInterviewDetails.rejected,
      (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message ?? "Something went wrong!";
      }
    );
    builder.addCase(getPastInterviewDetails.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getPastInterviewDetails.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.userPastCalls = payload;
    });
    builder.addCase(getPastInterviewDetails.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getParticularUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getParticularUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.particularUser = payload;
    });
    builder.addCase(getParticularUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getSuperAdminDetails.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getSuperAdminDetails.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.superAdmin = payload;
    });
    builder.addCase(getSuperAdminDetails.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getFeedbackAnswers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getFeedbackAnswers.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.feedbackAnswers = payload;
    });
    builder.addCase(getFeedbackAnswers.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getFeedbackWithAnswers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getFeedbackWithAnswers.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.feedbackWithAnswers = payload;
    });
    builder.addCase(getFeedbackWithAnswers.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(createOrganization.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createOrganization.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(createOrganization.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(addUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.isFetching = false;
      state.isUserAddedSuccess = true;
    });
    builder.addCase(addUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(isSubdomainExists.pending, (state) => {
      // state.isFetching = true;
    });
    builder.addCase(isSubdomainExists.fulfilled, (state, { payload }) => {
      // state.isFetching = false;
      state.isSubdomain = payload;
    });
    builder.addCase(isSubdomainExists.rejected, (state, { payload }) => {
      // state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(saveOrgCss.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(saveOrgCss.fulfilled, (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(saveOrgCss.rejected, (state, action) => {
      state.isFetching = false;
      state.errorMessage = action.payload?.message ?? "Something went wrong!";
    });
    builder.addCase(getAllUsersCsvDownload.pending, (state) => {
      state.isLoaderTrue = true;
    });
    builder.addCase(getAllUsersCsvDownload.fulfilled, (state) => {
      state.isLoaderTrue = false;
      state.csvDownloaded = true;
    });
    builder.addCase(getAllUsersCsvDownload.rejected, (state, { payload }) => {
      state.isLoaderTrue = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Error downloading CSV! Please try again later";
    });
    builder.addCase(updateSpecificUser.pending, (state) => {
      state.isLoaderTrue = true;
    });
    builder.addCase(updateSpecificUser.fulfilled, (state) => {
      state.isLoaderTrue = false;
      state.isSuccess = true;
    });
    builder.addCase(updateSpecificUser.rejected, (state, { payload }) => {
      state.isLoaderTrue = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Error downloading CSV! Please try again later";
    });
  },
  
  //   extraReducers: (builder) => {
  //     builder.addCase(incrementBy, (state, action) => {
  //       return state + action.payload
  //     })
  //     builder.addCase(decrementBy, (state, action) => {
  //       return state - action.payload
  //     })
  //   },
});

export const { clearState } = superAdminSlice.actions;

export const superAdminData = (state) => state.superAdmin;
