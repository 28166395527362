import React from 'react';
import CountUp from 'react-countup';
import './ProfileCardStyle.css';
function ProfileCard({ img, number, text, className, onClick }) {
    return (
        <div className={`CardContactWrapper pointer ${className}`} onClick={onClick} >
            <span className='icontools flex-center'><img src={img} alt="" /></span>
            <div className="contentareacontact">
                <span><CountUp end={number} duration={1} /></span>

                <p className='mt-5 text2'>{text}</p>
            </div>
        </div>
    )
}

export default ProfileCard