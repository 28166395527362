import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import './UsersStyle.css';
// import ProfileCard from '../../components/ProfileCard/ProfileCard';
import CallHistory from './CallHistory';
import AllUsers from './AllUsers';
import Settings from './Setting'
import Overview from './Overview'
import AdminPopup from '../../components/PopUp/AdminPopup/AdminPopup';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, clearState, deleteUser, getAllPastCalls, getAllPrograms, getAllUpcomingCalls, getFeedbackAnswers, getFeedbackWithAnswers, getOrganizationDetails, superAdminData } from '../../reducer/superAdminSlice';
import PageLoader from '../../components/PageLoader/PageLoader';
import ViewCallDetails from '../../components/PopUp/ViewCallDetails/ViewCallDetails';
import SuccessToast from '../../components/Toast/SuccessToast';
import FilterPasscall from '../../components/PopUp/FilterPasscall/FilterPasscall';
// import PopFeedBack from '../../components/PopUp/PopFeedBack/PopFeedBack';
// import CallDetails from './CallDetails';

const Users = () => {

    const addAdminTemplate = {
        profileImg: {
            url: "",
            name: ""
        },
        firstName: "",
        lastName: "",
        designation: "",
        yrsOfExp: null,
        mobileNumber: "",
        email: "",
        linkedIn: ""
    };

    const errorAdminTemplate = {
        firstName: "",
        lastName: "",
        designation: "",
        yrsOfExp: "",
        mobileNumber: "",
        email: "",
        linkedIn: ""
    }

    const userAccessTemplate = {
        totalAdmin: "",
        totalMentor: "",
        totalMentee: "",
    };

    const permissionsTemplate = {
        internalMentors: false,
        colorScheme: false,
        emails: false,
        sms: false,
        whatsapp: false
    }

    const navigate = useNavigate();
    const [tabActive, setTabActive] = useState(2);
    const [userToggler, setUserToggler] = useState(1);
    const [isUpcomingCalls, setIsUpcomingCalls] = useState(true)

    //Add Admin States
    const [adminPopupToggler, setAdminPopupToggler] = useState(false);
    const [addAdmin, setAddAdmin] = useState(addAdminTemplate);
    const [errorAddAdmin, setErrorAddAdmin] = useState(errorAdminTemplate)
    const [isLoading, setIsLoading] = useState(false);

    //View Call Detail States
    const [viewCallDetails, setViewCallDetails] = useState(false);
    const [menteeFeedback, setMenteeFeedback] = useState({});
    const [mentorFeedback, setMentorFeedback] = useState({});

    //Setting States
    const [userAccess, setUserAccess] = useState(userAccessTemplate);
    const [permissions, setPermissions] = useState(permissionsTemplate);

    //Toast states
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    //Search states
    const [search, setSearch] = useState("");

    //Filter States
    const [filterToggler, setFilterToggler] = useState(false);
    const [programOptions, setProgramOptions] = useState([]);
    const [program, setProgram] = useState("");
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");

    //Load More states
    const [index, setIndex] = useState(1);
    const [totalUpcomingCalls, setTotalUpcomingCalls] = useState([]);
    const [totalPastCalls, setTotalPastCalls] = useState([]);
    const [totalAdmins, setTotalAdmins] = useState([]);
    const [totalMentors, setTotalMentors] = useState([]);
    const [totalMentees, setTotalMentees] = useState([]);

    const { isFetching, isOrgDetailsFetching, isSuccess, isUserAddedSuccess, isError, errorMessage, allUsers, organizationDetail, orgUpcomingCalls, orgPastCalls, feedbackAnswers, allPrograms, feedbackWithAnswers } = useSelector(superAdminData);

    const { orgId } = useParams();
    const dispatch = useDispatch();

    //View Call Details Functions
    const handleViewCallDetails = (id) => {
        setViewCallDetails(true);
        dispatch(getFeedbackWithAnswers(id));
    }

    const handleViewCallClose = () => {
        setViewCallDetails(false);
        setMenteeFeedback({});
        setMentorFeedback({});
    }

    //Add Admin Functions
    const addAdminChange = (e) => {
        setErrorAddAdmin(errorAdminTemplate);
        const { name, value } = e.target;
        if (name === "mobileNumber" && (value?.length > 10 || value < 0)) return;
        if (name === "yrsOfExp" && (value?.length > 2 || value < 0)) return;
        setAddAdmin(prev => ({
            ...prev,
            [name]: value
        }))
    };

    const addAdminValidation = () => {
        for (const key in errorAddAdmin) {
            if (!addAdmin[key]) {
                if (key === "lastName") continue;
                setErrorAddAdmin(prev => ({
                    ...prev,
                    [key]: "This field is mandatory"
                }));
                return true;
            }
        }
        if (addAdmin["mobileNumber"].length !== 10) {
            setErrorAddAdmin((prev) => ({
                ...prev,
                mobileNumber: "Please fill valid mobile number",
            }));
        }
        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(addAdmin["email"])) {
            setErrorAddAdmin((prev) => ({
                ...prev,
                email: "Please fill valid email",
            }));
            return true;
        }
    }

    const addAdminClose = () => {
        setAdminPopupToggler(false);
        setAddAdmin(addAdminTemplate);
    }

    const addAdminDiscard = () => {
        setAddAdmin(addAdminTemplate);
    };

    const addAdminSave = () => {
        if (!addAdminValidation()) {
            console.log("pp")
            const payload = addAdmin;
            addAdmin["profileImg"]["url"] ? payload["profileImg"] = addAdmin["profileImg"]["url"] : payload["profileImg"] = "";
            const obj = {
                user: "admin",
                orgId,
                obj: payload
            }
            dispatch(addUser(obj));
        }
    };

    //Delete Admin Functions
    

    //Setting Functions
    const userAccessChange = (e) => {
        const { name, value } = e.target;
        if (value < 0) return;
        setUserAccess(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const userAccessDiscard = () => {
        setUserAccess(userAccessTemplate);
    };

    const userAccessSave = () => {
        console.log("API HIT")
    };

    const permissionsChange = (e) => {
        const { name } = e.target;
        setPermissions(prev => ({
            ...prev,
            [name]: !prev[name]
        }));
    };

    const permissionsDiscard = () => {
        setPermissions(permissionsTemplate);
    };

    const permissionsSave = () => {
        console.log("API HIT")
    };

    const handleUploadProfile = (e) => {
        if (e.target.files.length > 0) {
            const files = e.target.files[0];
            setIsLoading(true);
            const data = new FormData();

            data.append("file", files);
            data.append("upload_preset", "qlm4yp8h");
            data.append("cloud_name", "df491ehfg");

            fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
                method: "POST",
                body: data,
            })
                .then((res) => res.json())
                .then((res) => {


                    setIsLoading(false);
                    setAddAdmin(prev => ({
                        ...prev,
                        profileImg: {
                            name: res.original_filename,
                            url: res.secure_url
                        }
                    }));
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    };

    //Search functions
    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearch(value);
    };

    const handleSearchPress = (e) => {
        if (e.key === "Enter") {
            setIndex(1);
            setTotalMentees([]);
            setTotalMentors([]);
            setTotalUpcomingCalls([]);
            setTotalPastCalls([]);
            if (tabActive === 2) {
                if (isUpcomingCalls) dispatch(getAllUpcomingCalls({ orgId, search, date, program }))
                else dispatch(getAllPastCalls({ orgId, search, date, program, status }));
            };
            if (tabActive === 3) {
                // if (userToggler === 1) dispatch(getAllUsers({ orgId, user: "admin", search }));
                // if (userToggler === 2) dispatch(getAllUsers({ orgId, user: "mentor", search }));
                // if (userToggler === 3) dispatch(getAllUsers({ orgId, user: "mentee", search }));
            }
        }
    };

    //Filter functions
    const handleProgramFilter = (e) => {
        const { value } = e.target;
        setProgram(value);
    };

    const handleDateFilter = (e) => {
        const { value } = e.target;
        setDate(value);
    };

    const handleStatusFilter = (e) => {
        const { value } = e.target;
        setStatus(value);
    };

    const handleApplyFilters = () => {
        setIndex(1);
        setTotalMentees([]);
        setTotalMentors([]);
        setTotalUpcomingCalls([]);
        setTotalPastCalls([]);
        if (tabActive === 2) {
            if (isUpcomingCalls) dispatch(getAllUpcomingCalls({ orgId, search, date, program }))
            else dispatch(getAllPastCalls({ orgId, search, date, program, status }));
        }
        setFilterToggler(false);
    };

    const handleFilterClose = () => {
        setFilterToggler(false);
    };

    //Load more functions
    const handleCallsLoadMore = () => {
        setIndex(prev => prev + 1);
        if (isUpcomingCalls) dispatch(getAllUpcomingCalls({ orgId, search, date, program, index: index + 1 }))
        else dispatch(getAllPastCalls({ orgId, search, date, program, status, index: index + 1 }));
    };

    const handleUsersLoadMore = () => {
        setIndex(prev => prev + 1);
        if (tabActive === 3) {
            // if (userToggler === 1) dispatch(getAllUsers({ orgId, user: "admin", search, index: index + 1 }));
            // if (userToggler === 2) dispatch(getAllUsers({ orgId, user: "mentor", search, program, index: index + 1 }));
            // if (userToggler === 3) dispatch(getAllUsers({ orgId, user: "mentee", search, program, index: index + 1 }));
        }
    };

    //Load more useEffects
    useEffect(() => {
        if (orgUpcomingCalls?.calls) setTotalUpcomingCalls(prev => [...prev, ...orgUpcomingCalls?.calls]);
        if (orgPastCalls?.calls) setTotalPastCalls(prev => [...prev, ...orgPastCalls?.calls]);
    }, [orgUpcomingCalls, orgPastCalls]);

    useEffect(() => {
        if (allUsers?.users?.length > 0 && tabActive === 3) {
            if (userToggler === 2) setTotalMentors(prev => [...prev, ...allUsers?.users]);
            if (userToggler === 3) setTotalMentees(prev => [...prev, ...allUsers?.users]);
        }
    }, [allUsers])

    useEffect(() => {
        dispatch(getOrganizationDetails(orgId));
        dispatch(getAllPrograms(orgId));
    }, [])

    //Filter useEffect
    useEffect(() => {
        if (allPrograms) {
            setProgramOptions([]);
            allPrograms?.forEach(item => {
                setProgramOptions(prev => [
                    ...prev,
                    {
                        name: item?.programName,
                        value: item?.id
                    }
                ])
            });
        }
    }, [allPrograms]);

    useEffect(() => {
        if (tabActive === 3) {
            setIndex(1);
            setTotalAdmins([]);
            setTotalMentees([]);
            setTotalMentors([]);
            setTotalUpcomingCalls([]);
            setTotalPastCalls([]);
            // if (userToggler === 1) dispatch(getAllUsers({ orgId, user: "admin", search }));
            // if (userToggler === 2) dispatch(getAllUsers({ orgId, user: "mentor", search, program }));
            // if (userToggler === 3) dispatch(getAllUsers({ orgId, user: "mentee", search, program }));
        }
    }, [program])

    useEffect(() => {
        setIndex(1);
        setTotalMentees([]);
        setTotalMentors([]);
        setTotalUpcomingCalls([]);
        setTotalPastCalls([]);
        if (tabActive === 2) {
            if (isUpcomingCalls) dispatch(getAllUpcomingCalls({ orgId }))
            else dispatch(getAllPastCalls({ orgId }));
        }
        if (tabActive === 3) {
            // if (userToggler === 1) dispatch(getAllUsers({ orgId, user: "admin" }));
            // if (userToggler === 2) dispatch(getAllUsers({ orgId, user: "mentor" }));
            // if (userToggler === 3) dispatch(getAllUsers({ orgId, user: "mentee" }));
        }
    }, [tabActive, userToggler, isUpcomingCalls]);

    useEffect(() => {
        if (isUserAddedSuccess) {
            setAdminPopupToggler(false);
            setAddAdmin(addAdminTemplate);
            // dispatch(getAllUsers({ orgId, user: "admin" }));
            dispatch(clearState());
        }
    }, [isUserAddedSuccess]);

    useEffect(() => {
        if (isSuccess) {
            setIsShowSuccess(true);
            setSuccessMessage("Success!");
            setIndex(1);
            setTotalMentees([]);
            setTotalMentors([]);
            setTotalUpcomingCalls([]);
            setTotalPastCalls([]);
            // dispatch(getAllUsers({ orgId, user: "admin" }));
            dispatch(clearState());
        }
    }, [isSuccess]);

    //View call details useEffect
    useEffect(() => {
        if (feedbackWithAnswers?.feedbacks?.length > 0) {
            const menteeArr = feedbackWithAnswers?.feedbacks?.filter((item) => item.for === "mentor");
            const mentorArr = feedbackWithAnswers?.feedbacks?.filter((item) => item.for === "mentee");
            setMenteeFeedback(...menteeArr);
            setMentorFeedback(...mentorArr);
        }
    }, [feedbackWithAnswers]);

    return (
        <>
            {((isFetching || isOrgDetailsFetching) || isLoading) && <PageLoader />}
            <SuccessToast
                show={isShowSuccess}
                setShow={() => setIsShowSuccess(false)}
                message={successMessage}
            />
            <AdminPopup isActive={adminPopupToggler} setIsActive={setAdminPopupToggler} valueObj={addAdmin} onChange={addAdminChange} onProfileChange={handleUploadProfile} onClickClose={addAdminClose} onAddAdminDiscard={addAdminDiscard} onAddAdminSave={addAdminSave} isError={errorAddAdmin} />
            <ViewCallDetails isActive={viewCallDetails} handleClose={handleViewCallClose} callInfo={feedbackWithAnswers?.callInfo} menteeFeedback={menteeFeedback} mentorFeedback={mentorFeedback} preSessionMentee={feedbackWithAnswers?.preSessionMentee} postSessionMentee={feedbackWithAnswers?.postSessionMentee} postSessionMentor={feedbackWithAnswers?.postSessionMentor} />
            <FilterPasscall isActive={filterToggler} handleClose={handleFilterClose} isPastCalls={!isUpcomingCalls} programs={programOptions} handleProgramFilter={handleProgramFilter} handleDateFilter={handleDateFilter} handleStatusFilter={handleStatusFilter} date={date} status={status} handleApplyFilters={handleApplyFilters} />
            <section className='DashboardSection UsersSectionwrap'>
                <div className="DashboardWrapper">
                    <div className="headingwrap">
                        <h2 className='heading-2'>Organization</h2>
                        <p><span className='linktabAtive' onClick={() => { navigate(`/allUser`) }}>Home</span> / All Users</p>
                    </div>
                    <div className="DashboardOptionsCard flex-col userWrapperprofile">
                        <div className="profilewrapper w-100 ">
                            <div className="profileDetailsview mu-imgusers">
                                <div className='Imageboxall mr-20'>

                                    <img src={organizationDetail?.orgData?.logoUrl ? organizationDetail?.orgData?.logoUrl : "/assets/img/user1.png"} alt="" className='orglogo' />
                                </div>
                                <div className="ProfileDetailsarea w-100">
                                    <div className="headingwrap">
                                        <div className="headingorg">
                                            <h2 className='heading-2'>{organizationDetail?.orgData?.name ?? "---"}</h2>
                                            {/* <span>Yearly</span> */}
                                        </div>
                                        <p>{organizationDetail?.orgData?.description ?? "---"}</p>

                                    </div>
                                    <div className="CardsProfilWrapper">
                                        <ProfileCard number={organizationDetail?.programCount ?? 0} text={'All Programs'} img={"/assets/img/UserProgram.png"} onClick={() => setTabActive(2)} />
                                        <ProfileCard number={organizationDetail?.callsCount ?? 0} text={'Number of Calls'} img={"/assets/img/contact/call.png"} onClick={() => setTabActive(2)} />
                                        <ProfileCard className={'purpleCard'} number={organizationDetail?.usersCount?.mentor ?? 0} text={'Number of Mentors'} img={"/assets/img/contact/User1.png"} onClick={() => { setTabActive(3); setUserToggler(2) }} />
                                        <ProfileCard className={'greenCard'} number={organizationDetail?.usersCount?.mentee ?? 0} text={'Number of Mentees'} img={"/assets/img/contact/usergreen.png"} onClick={() => { setTabActive(3); setUserToggler(3) }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="MenusTabWrapper w-100">
                            <ul className='flex gap-30 items-center'>
                                {/* <li onClick={() => setTabActive(1)} className={tabActive === 1 && 'active'}>Overview</li> */}
                                <li onClick={() => setTabActive(2)} className={tabActive === 2 && 'active'}>Call History</li>
                                <li onClick={() => setTabActive(3)} className={tabActive === 3 && 'active'}>Users</li>
                                {/* <li onClick={() => setTabActive(4)} className={tabActive === 4 && 'active'}>Settings</li> */}
                            </ul>
                        </div>

                    </div>

                </div>

                <Overview isActive={tabActive === 1 && 'active'} />
                <CallHistory isActive={tabActive === 2 && 'active'} isUpcomingCalls={isUpcomingCalls} setIsUpcomingCalls={setIsUpcomingCalls} upcomingCalls={orgUpcomingCalls} pastCalls={orgPastCalls} totalUpcomingCalls={totalUpcomingCalls} totalPastCalls={totalPastCalls} orgId={orgId} handleViewCallDetails={handleViewCallDetails} search={search} handleSearchChange={handleSearchChange} handleSearchPress={handleSearchPress} setFilterToggler={setFilterToggler} handleLoadMore={handleCallsLoadMore} />
                <AllUsers isActive={tabActive === 3 && 'active'} orgId={orgId} userToggler={userToggler} setUserToggler={setUserToggler} setAdminPopupToggler={setAdminPopupToggler} data={userToggler === 1 ? allUsers : userToggler === 2 ? totalMentors : totalMentees} userData={allUsers} search={search} handleSearchChange={handleSearchChange} handleSearchPress={handleSearchPress} setFilterToggler={setFilterToggler} programs={programOptions} handleProgramFilter={handleProgramFilter} handleLoadMore={handleUsersLoadMore} />
                <Settings isActive={tabActive === 4 && 'active'} userAccess={userAccess} onUserAccessChange={userAccessChange} onUserAccessDiscard={userAccessDiscard} onUserAccessSave={userAccessSave} permissions={permissions} onPermissionsChange={permissionsChange} onPermissionsDiscard={permissionsDiscard} onPermissionsSave={permissionsSave} />

                {/* <PopFeedBack /> */}

            </section>
        </>
    )
}

export default Users