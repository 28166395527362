import React from 'react'
import './InputFieldStyle.css'

const InputField2 = ({ type, className, placeholder, id, labelName, checkBoxWrap, onChange, disabled, value, checked, errorMessage, notImp, onKeyDown, blackLabel, name, onBlur }) => {
    return (
        <div className={`formGroups ${checkBoxWrap ?? ""}`}>
            <label className={blackLabel && 'visible0'} htmlFor={id}>{labelName}
                {!notImp && <span className={"impRed"}> *</span>}
            </label>
            <input onBlur={onBlur} type={type} className={className} placeholder={placeholder} onKeyDown={onKeyDown} disabled={disabled} value={value} onChange={onChange} id={id} checked={checked} name={name} />
            {errorMessage && <span className='FormError'>{errorMessage}</span>}
        </div>
    )
}

export default InputField2