import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../MentorsProfile/MentorsProfileStyle.css";
import Button from "../../components/Button/Button";
import moment from "moment/moment";
function CallHistoryMentees({ isActiveTab, isFetching, isUpcomingCalls, setIsUpcomingCalls, upcomingCalls, pastCalls, upcoming, past, handleLoadMore, onClickViewFeedback }) {
  const navigate = useNavigate();

  return (
    <div
      className={`ContainerTabForAllTabData MentorOverviewData  ${isActiveTab && "active"
        }`}
    >
      <div className="Headingforallheader">
        <div className="Activitiesheading  flex-between w-100">
          <h2 className="heading-2">Call Details</h2>
          <div className="btnRightsidesale">
            <Button
              name={"Upcoming"}
              onClick={() => setIsUpcomingCalls(true)}
              className={isUpcomingCalls && "active"}
            />
            <Button
              name={"Past"}
              onClick={() => setIsUpcomingCalls(false)}
              className={!isUpcomingCalls && "active"}
            />
          </div>
        </div>
      </div>

      <div className="border-btm  w-100"> </div>
      <div className="bodyDataForallUserdata">
        <div
          className={`UsersCallProfileWrapper CallHistoryusersTab mt-20 autoscrolltable ${isUpcomingCalls && "active"
            }`}
        >
          <table>
            <thead>
              <tr className="headingTabletr">
                <th>Mentee Name</th>
                <th>Topic of Discussion</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {upcomingCalls?.length > 0 ? upcomingCalls?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div
                      className="mentorProfilearea"
                      onClick={() => {
                        navigate(`/mentorprofile/${item?.organizationId}/${item?.mentor?.id}`);
                      }}
                    >
                      <div className="ImaUserhistory">
                        <img
                          src={item?.mentor?.profileImg ? item?.mentor?.profileImg : "/assets/img/User.png"}
                          alt=""
                          className="pointer"
                        />
                      </div>

                      <div className="Namedetails">
                        <h4 className="namelink pointer">{item?.mentor?.firstName ? `${item?.mentor?.firstName} ${item?.mentor?.lastName}` : "---"}</h4>
                        <p className="text2">{item?.mentor?.profession?.designation ?? "---"}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mentorProfilearea">
                      <div className="Namedetails">
                        <h4>{item?.mentor?.topics?.length > 0 ? item?.mentor?.topics[0]?.Topic : "---"}</h4>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mentorProfilearea">
                      <div className="Namedetails">
                        <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("DD MMMM, YYYY") : "---"}</h4>
                        <p className="text2">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("dddd") : "---"}</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="mentorProfilearea">
                      <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("h:mm a") : "---"}</h4>
                    </div>
                  </td>
                </tr>
              )) : "No calls yet"}
            </tbody>
          </table>
          {(!isFetching && ((isUpcomingCalls && upcoming?.length >= 10) || (!isUpcomingCalls && past?.length >= 10))) && <div className="ViewMoreButton pb-20">
            <Button name={'Load More'} className={'BlueFillButton buttontext'} onClick={handleLoadMore} />
          </div>}
        </div>
        <div
          className={`UsersCallProfileWrapper CallHistoryusersTab mt-20 autoscrolltable ${!isUpcomingCalls && "active"}`}
        >
          <table>
            <thead>
              <tr className="headingTabletr">
                <th>Mentee Name</th>
                <th>Topic of Discussion</th>
                <th>Date</th>
                <th>Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pastCalls?.length > 0 ? pastCalls?.map((item, index) => (
                <tr>
                  <td>
                    <div
                      className="mentorProfilearea"
                      onClick={() => {
                        navigate(`/mentorprofile/${item?.organizationId}/${item?.mentor?.id}`);
                      }}
                    >

                      <div className="ImaUserhistory">
                        <img
                          src={item?.mentor?.profileImg ? item?.mentor?.profileImg : "/assets/img/User.png"}
                          alt=""
                          className="pointer"
                        />
                      </div>

                      <div className="Namedetails">
                        <h4 className="pointer namelink ">{item?.mentor?.firstName ? `${item?.mentor?.firstName} ${item?.mentor?.lastName}` : "---"}</h4>
                        <p className="text2">{item?.mentor?.profession?.designation ?? "---"}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mentorProfilearea">
                      <div className="Namedetails">
                        <h4>{item?.mentor?.topics?.length > 0 ? item?.mentor?.topics[0]?.Topic : "---"}</h4>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mentorProfilearea">
                      <div className="Namedetails">
                        <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("DD MMMM, YYYY") : "---"}</h4>
                        <p className="text2">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("dddd") : "---"}</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="mentorProfilearea">
                      <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("h:mm a") : "---"}</h4>
                    </div>
                  </td>
                  <td>
                    <div className="mentorProfilearea">
                      <Button
                        name={"View Feedback"}
                        className="BlueFillButton buttontext"
                        onClick={() => onClickViewFeedback(item?.id)}
                      />
                    </div>
                  </td>
                </tr>
              )) : "No calls yet"}
            </tbody>
          </table>
          {(!isFetching && ((isUpcomingCalls && upcoming?.length >= 10) || (!isUpcomingCalls && past?.length >= 10))) && <div className="ViewMoreButton pb-20">
            <Button name={'Load More'} className={'BlueFillButton buttontext'} onClick={handleLoadMore} />
          </div>}
        </div>
      </div>

    </div>
  );
}

export default CallHistoryMentees;
