import React from 'react';
import Button from '../../Button/Button';
import InputField from '../../InputField/InputField';

import './AdminPopupStyle.css';

function AdminPopup({ isActive, setIsActive, valueObj, onChange, onProfileChange, onAddAdminDiscard, onAddAdminSave, onClickClose, isError }) {
    return (
        <div className={`popupWrapper feedbackpopup PopupAdminContainer ${isActive && "active"}`}>
            <div className="popupContainer">
                <div className="popupHeader">
                    <span className="close"><img src="/assets/svg/close.svg" alt="" onClick={onClickClose} /></span>
                </div>
                <div className="popupBody PopupBodyAdmin">
                    <div className="headingwrapper">
                        <h3>Add Admin</h3>
                        {/* <p className="text2">Sed ut perspiciatis unde omnis iste natus error
                            sit voluptatem accusantium doloremque laudantium</p> */}
                    </div>
                    <div className="AdminFormContentwrapper">
                        <div className="userProfileEdit">
                            <h4>Avatar</h4>
                            <div class="leftWrapperPrfile PopupWrapperPrfile">
                                <div class="userProfileEditTab">
                                    <label class="userProfile pointer" for="bgimg">
                                        <img src={valueObj["profileImg"]["url"] ? valueObj["profileImg"]["url"] : "/assets/img/user1.png"} alt="" className='userimg'/>
                                        <input type="file" name="bgimg" id="bgimg" accept='image/*' value={e => e.target.value} onChange={onProfileChange} />
                                        <img src="/assets/img/edit.png" alt="" class="editimg pointer" />
                                    </label>
                                </div>
                                <p className='mt-10 text2'>{valueObj["profileImg"]["name"] ? valueObj["profileImg"]["name"] : "File types allowed are: jpg, jpeg, png"}</p>
                            </div>
                        </div>
                        <div className="inputFieldwrapper1 Redhidendot infoicon">
                            <InputField name={"firstName"} value={valueObj["firstName"]} onChange={onChange} errorMessage={isError["firstName"]} type={'text'} placeholder={'Enter first name'} id={'AdminCount'} label={'Full Name'} className="inputfirstname"/>
                            <div className='inputfielddiv'>
                                <InputField name={"lastName"} value={valueObj["lastName"]} onChange={onChange} errorMessage={isError["lastName"]} type={'text'} placeholder={'Enter last name'} id={'AdminCount'} />
                            </div>
                        </div>
                        <div className="inputFieldwrapper">
                            <InputField name={"designation"} value={valueObj["designation"]} onChange={onChange} errorMessage={isError["designation"]} type={'text'} placeholder={'Enter designation'} id={'AdminCount'} label={'Designation'} />
                        </div>
                        <div className="inputFieldwrapper">
                            <InputField name={"yrsOfExp"} value={valueObj["yrsOfExp"]} onChange={onChange} errorMessage={isError["yrsOfExp"]} type={'number'} placeholder={'Enter years of experience'} id={'AdminCount'} label={'Years of exp'} />
                        </div>
                        <div className="inputFieldwrapper Redhidendot infoicon">
                            <InputField name={"mobileNumber"} value={valueObj["mobileNumber"]} onChange={onChange} errorMessage={isError["mobileNumber"]} type={'number'} placeholder={'Enter mobile number'} id={'AdminCount'} label={'Phone Number'} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                        </div>
                        <div className="inputFieldwrapper Redhidendot">
                            <InputField name={"email"} value={valueObj["email"]} onChange={onChange} errorMessage={isError["email"]} type={'text'} placeholder={'Enter email id'} id={'AdminCount'} label={'Email'} />
                        </div>
                        <div className="inputFieldwrapper">
                            <InputField name={"linkedIn"} value={valueObj["linkedIn"]} onChange={onChange} errorMessage={isError["linkedIn"]} type={'text'} placeholder={'Enter linkedin'} id={'AdminCount'} label={'Linkedin Profile'} />
                        </div>

                    </div>
                    <div className="FooterAdminPopup flex-center">
                        <Button name={'Discard'} onClick={onAddAdminDiscard} className="GreyFillButton buttontext"/>
                        <Button name={'Add'} onClick={onAddAdminSave} className="BlueFillButton buttontext"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPopup