import React from 'react';
import '../MentorsProfile/MentorsProfileStyle.css';
function OverviewMentees({ isActiveTab, data }) {
    return (
        <div className={`ContainerTabForAllTabData MentorOverviewData ${isActiveTab && 'active'}`}>

            <div className="Headingforallheader">
                <div className="Activitiesheading  flex-between w-100">
                    <h2 className="heading-2">Profile Details</h2>
                </div>
            </div>

            <div className="border-btm  w-100"></div>

            {/* Body */}
            <div className="bodyDataForallUserdata">
            <div className="Bodymentordatawrap">
                <div className="dataWrapperMenteeListitems">
                    <div className="RightListDataMenteeProfile leftheadingListMentee">
                        <div className="leftHeadingmenteelist">First Name</div>
                        <div className="leftHeadingmenteelist">Last Name</div>
                        <div className="leftHeadingmenteelist">Phone Number	</div>
                        <div className="leftHeadingmenteelist">Email</div>
                    </div>

                    <div className="RightListDataMenteeProfile">
                        <div className="rightdatamenteelist">{data?.firstName ?? "---"}</div>
                        <div className="rightdatamenteelist">{data?.lastName ?? "---"}</div>
                        <div className="rightdatamenteelist">{data?.mobileNumber ?? "---"}</div>
                        <div className="rightdatamenteelist">{data?.email ?? "---"}</div>
                    </div>
                </div>
            </div>
            <div className="Dashborder"></div>
            <div className="Bodymentordatawrap">
                <div className="dataWrapperMenteeListitems">
                    {data?.profession?.title === "Student" ? <div className=" leftheadingListMentee">
                        <div className="leftHeadingmenteelist">Title</div>
                        <div className="leftHeadingmenteelist">Qualification</div>
                        <div className="leftHeadingmenteelist">Current Year</div>
                    </div> :
                        <div className=" leftheadingListMentee">
                            <div className="leftHeadingmenteelist">Designation</div>
                            <div className="leftHeadingmenteelist">Company</div>
                            <div className="leftHeadingmenteelist">Years of Experience</div>
                        </div>}

                    {data?.profession?.title === "Student" ? <div className="RightListDataMenteeProfile">
                        <div className="rightdatamenteelist">{data?.profession?.title ? data?.profession?.title === "Work" ? "Working Professional" : "Student" : "---"}</div>
                        <div className="rightdatamenteelist">{data?.profession?.designation ?? "---"}</div>
                        <div className="rightdatamenteelist">{data?.profession?.yrsOfExp ?? "---"}</div>
                    </div> :
                        <div className="RightListDataMenteeProfile">
                            <div className="rightdatamenteelist">{data?.profession?.designation ?? "---"}</div>
                            <div className="rightdatamenteelist">{data?.profession?.company ?? "---"}</div>
                            <div className="rightdatamenteelist">{data?.profession?.yrsOfExp ?? "---"}</div>
                        </div>}
                </div>
                </div>
            </div>
           
        </div>
    )
}

export default OverviewMentees