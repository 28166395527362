import React, { useState } from 'react'
import Button from '../../Button/Button';
import Progressbar from '../../ProgressBar/Progressbar';


function MenteeFeedbackPopup({ isActive, menteeFeedback, mentorFeedback, onFeedbackClose }) {
    const [tabActive, setTabActive] = useState(1);

    return (
        <div className={`popupWrapper Superhsviewfed ${isActive && "active"}`}>
            <div className="popupContainer">
                <div className="popupHeader">
                    <span className="close"><img src="/assets/svg/close.svg" alt="" onClick={onFeedbackClose} /></span>
                </div>
                <div className="popupBody">
                    <div className="headingwrapper">
                        <h3>View Feedback</h3>
                    </div>
                    <div className="btnwrapperfeedback flex-center pillButtons">
                        <Button className={tabActive === 1 && 'active'} name={'Given'} onClick={() => setTabActive(1)} />
                        <Button className={tabActive === 2 && 'active'} name={'Received'} onClick={() => setTabActive(2)} />
                    </div>
                    <div className={`ProgressbarPopwrapper ${tabActive === 2 && 'active'}`}>
                        <div className="QuestionProgressbarPop">
                            <h3>How was your experience with the mentee?</h3>
                            <Progressbar count={mentorFeedback?.answer1} />
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>What was the mentee’s problem about?</h3>
                            <p className="text2">{mentorFeedback?.answer2}</p>
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>Were you able to come up with an action plan for the mentee?</h3>
                            <p className="text2 capitalize">{mentorFeedback?.answer3}</p>
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>How would you rate the mentee’s employability, if you were to hire them for your own team?</h3>
                            <Progressbar count={mentorFeedback?.answer4} />
                        </div>
                    </div>
                    <div className={`ProgressbarPopwrapper ${tabActive === 1 && 'active'}`}>
                        <div className="QuestionProgressbarPop">
                            <h3>How was your experience with the mentor?</h3>
                            <Progressbar count={menteeFeedback?.answer4} />
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>Did the call help you get any clarity?</h3>
                            <p className="text2">{menteeFeedback?.answer1}</p>
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>Would you like to schedule a call again with the same mentor?</h3>
                            <p className="text2">{menteeFeedback?.answer2}</p>
                        </div>
                        <div className="QuestionProgressbarPop">
                            <h3>Was the mentor able to answer your questions?</h3>
                            <p className="text2">{menteeFeedback?.answer3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenteeFeedbackPopup