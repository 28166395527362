import React from 'react'
import InputField from '../../components/InputField/InputField'
import Button from '../../components/Button/Button'
import './ConfigureStyle.css'
import DropdownCustom from '../../components/DropdownCustom/DropdownCustom'
import { useNavigate } from 'react-router-dom'

const CreatePlan = () => {

    const statusOptions = [
        {
            label: 'Active',
            value: "true"
        },
        {
            label: 'Inactive',
            value: "false"
        }
    ];

    const navigate = useNavigate();

    return (
        <section className="Appliedfinancemain createCustomPlan">
            <div className="MymentorsHeading flex">
                <div className="backarrowdiv" onClick={()=> navigate('/configure')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5303 4.46967C12.8232 4.76256 12.8232 5.23744 12.5303 5.53033L6.81066 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H6.81066L12.5303 18.4697C12.8232 18.7626 12.8232 19.2374 12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303L4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967Z" fill="black" />
                    </svg>
                </div>

                <div className="headingwrap">
                    <h2 className="heading-2">Create Ticket</h2>
                    <p className='text2'>Explain your query</p>
                </div>
            </div>

            <div className='ContainerTabForAllTabData'>
                <div className="SpbodyDataForalldata">
                    <div className="mulipleFormInputWrap flexbox">
                        <h4 className='lableHeading'>Plan Name <span className="impRed"> *</span></h4>
                        <div className="multipleFormInput flex-center">
                            <InputField
                                type={"text"}
                                notImp={true}
                                placeholder={"Enter your ticket subject"}
                            />
                        </div>
                    </div>

                    <div className="mulipleFormInputWrap flexbox">
                        <h4 className='lableHeading'>Organization<span className="impRed"> *</span></h4>
                        <div className="multipleFormInput flex-center">
                        <DropdownCustom options={statusOptions} placeholder={'minutes'} src={'/assets/img/arrow-down2.png'} />
                        </div>
                    </div>

                    <div className="mulipleFormInputWrap flexbox">
                        <h4 className='lableHeading'>Monthly Fee </h4>
                        <div className="multipleFormInput flex-center">
                            <InputField
                                type={"text"}
                                notImp={true}
                                placeholder={"Input monthly amount for the plan"}
                            />
                        </div>
                    </div>
                    <div className="mulipleFormInputWrap flexbox">
                        <h4 className='lableHeading'>Calls per month </h4>
                        <div className="multipleFormInput flex-center">
                            <InputField
                                type={"text"}
                                notImp={true}
                                placeholder={"Input call count"}
                            />
                        </div>
                    </div>
                    <div className="mulipleFormInputWrap flexbox">
                        <h4 className='lableHeading'>Credits per user</h4>
                        <div className="multipleFormInput flex-center">
                            <InputField
                                type={"text"}
                                notImp={true}
                                placeholder={"Input credits per user"}
                            />
                        </div>
                    </div>
                </div>

                <div className="border-btm w-100"></div>

                <div className="Headingforallheader">
                    <div className="flex gap-10">
                        <Button name={'Discard'} className={' GreyFillButton'} />
                        <Button name={'Save'} className=" BlueFillButton" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreatePlan