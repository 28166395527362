import React from 'react';
import './AllNotificationsStyle.css';
import Button from '../../components/Button/Button';
export const AllNotifications = ({ isActive }) => {
    return (
        <section className={`DashboardSection ${isActive && "active"}`}>
            <div className="DashboardWrapper">
                <div className="headingwrap">
                    <h2 className='heading-2'>Notifications</h2>
                </div>
                <div className="flex-center">
                    <div className="AllNotificationWrapper">
                        <ul className='NotificationList AllNotificationList'>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                            <li>
                                <img src="/assets/img/Dropdownimg.png" alt="" />
                                <div className="notificationBel">
                                    <div className="nameuserNotification">
                                        <span> Nimisha Shrivastava</span> added a mentor.
                                        <p className="timeNofication text2">13 minutes ago</p>
                                    </div>
                                    <span className='Bluedot'></span>
                                </div>
                            </li>
                        </ul>
                        <div className="ViewMorenotification flex-center">
                            <Button  name={'View more'} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
