import React, { useState } from 'react';
import './DashboardStyle.css';
import Cardoption from '../../components/Cardoption/Cardoption';
import Faq from '../../components/FaqQution/Faq';
import Button from '../../components/Button/Button';
import { Navigate } from 'react-router-dom';
// import Collapsible from 'react-collapsible';

const Dashboard = () => {
  return (<Navigate to={"/allUser"} />)
  // const [TabOpenMonth, setTabOpenMonth] = useState(1);
  // const [openit, setOpenit] = useState(1);

  // return (
  //   <section className='DashboardSection'>
  //     <div className="headingwrap">
  //       <h2 className='heading-2'>Overview</h2>
  //       <p className="text2">All Details</p>
  //     </div>
  //     <div className="DashboardOptionsCard DashboardCardoptionmain">
  //       <Cardoption progress={'down'} heading={'TOTAL INCOME'} money={'85.500'} graph={'12'} img={'Dollaricon'} className={'yellowTheme'} />
  //       <Cardoption heading={'DAILY REVENUE'} money={'85.500'} graph={'12'} img={'Graphicon'} className={'purpleTheme'} />
  //       <Cardoption heading={'ALL ORGANIZATIONS'} money={'85.500'} graph={'12'} img={'UserTick'} className={'greenTheme'} />
  //       <Cardoption heading={'TOTAL USERS'} money={'85.500'} graph={'12'} img={'user'} className={'blueTheme'} />
  //     </div>

  //     <div className="SaleorActivityWrapper">
  //       {/* Graph area */}
  //       <div className="SalesPerformanceWrapper">
  //         <div className="headingSaleswrap flex-between">
  //           <div className="LeftSideSales">
  //             <h2 className='heading-2  mb-5'>Sales Performance</h2>
  //             <p className="text2">Organizational earnings</p>
  //           </div>
  //           <div className="btnRightsidesale">
  //             <Button name={'Month'} onClick={() => setTabOpenMonth(1)} className={TabOpenMonth === 1 && 'active'} />
  //             <Button name={'Week'}  onClick={() => setTabOpenMonth(2)} className={TabOpenMonth === 2 && 'active'}/>
  //           </div>
  //         </div>
  //         {/* Tab Content */}
  //         <div className={`MonthorDayesWrapper ${TabOpenMonth === 1 && 'active'}`}>
  //           <div className="LeftDollerheading flex items-center gap-6">
  //             <h4>$855.21</h4>
  //             <p className="text2"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  //               <path d="M9.035 5.15973C8.94 5.15973 8.845 5.12473 8.77 5.04973L6 2.27973L3.23 5.04973C3.085 5.19473 2.845 5.19473 2.7 5.04973C2.555 4.90473 2.555 4.66473 2.7 4.51973L5.735 1.48473C5.88 1.33973 6.12 1.33973 6.265 1.48473L9.3 4.51973C9.445 4.66473 9.445 4.90473 9.3 5.04973C9.23 5.12473 9.13 5.15973 9.035 5.15973Z" fill="#7BC896" />
  //               <path d="M6 10.625C5.795 10.625 5.625 10.455 5.625 10.25V1.83496C5.625 1.62996 5.795 1.45996 6 1.45996C6.205 1.45996 6.375 1.62996 6.375 1.83496V10.25C6.375 10.455 6.205 10.625 6 10.625Z" fill="#7BC896" />
  //             </svg> 2.1%</p>
  //           </div>
  //           <p className="text2">Average Cost per Organization</p>
  //         </div>
  //         <div className={`MonthorDayesWrapper ${TabOpenMonth === 2 && 'active'}`}>
  //           <p className="text2">Week</p>
  //         </div>
  //         {/*End Tab Content */}
  //       </div>
  //       {/* Activities Area */}
  //       <div className="ActivitiesWrapper">
  //         <div className="Activitiesheading">
  //           <h2 className='heading-2 mb-5'>Activities</h2>
  //           <p className="text2">Recent Updations</p>
  //         </div>
  //         {/* Faq */}
  //         <div className="ActivitiesWrapperFaq DashBoardWrapperFaq">
  //           {/* Faq Component */}
  //           <Faq onClick={() => setOpenit(1)} classname={openit === 1 && 'active'} heading={'At vero eos et accusamus'} text={'Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atquet vero eos et accusamus et iusto :'} list1={'deleniti atquet vero eos et'} list2={'accusamus et iusto'}
  //             list3={' blanditiis praesentium voluptatum'} icon={'-'} className={'purplebg'} time={'02:30'} />
  //           <Faq classname={openit === 2 && 'active'} text={'Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atquet vero eos et accusamus et iusto :'} list1={'deleniti atquet vero eos et'} list2={'accusamus et iusto'}
  //             list3={' blanditiis praesentium voluptatum'} onClick={() => setOpenit(2)} heading={'Temporibus autem quibusdam et aut officiis'} className={'yellowbg'} time={'06:10'} />
  //           <Faq classname={openit === 3 && 'active'} text={'Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atquet vero eos et accusamus et iusto :'} list1={'deleniti atquet vero eos et'} list2={'accusamus et iusto'}
  //             list3={' blanditiis praesentium voluptatum'} onClick={() => setOpenit(3)} heading={'Temporibus autem quibusdam et aut officiis'} className={'bluebg'} time={'10:15'} />
  //           <Faq classname={openit === 4 && 'active'} text={'Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atquet vero eos et accusamus et iusto :'} list1={'deleniti atquet vero eos et'} list2={'accusamus et iusto'}
  //             list3={' blanditiis praesentium voluptatum'} onClick={() => setOpenit(4)} heading={'Temporibus autem quibusdam et aut officiis'} className={'greenbg'} time={'12:03'} />
  //           <Faq classname={openit === 5 && 'active'} text={'Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atquet vero eos et accusamus et iusto :'} list1={'deleniti atquet vero eos et'} list2={'accusamus et iusto'}
  //             list3={' blanditiis praesentium voluptatum'} onClick={() => setOpenit(5)} heading={'Temporibus autem quibusdam et aut officiis'} className={'graybg'} time={'16:21'} />
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // )
}

export default Dashboard;