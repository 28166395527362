import React from 'react'
import './UserCardStyle.css';
function UserCard({colname, tag, logo, totalUser, sales, amount, classname, onClick,mentorImg , mentorImg2,mentorImg3,mentorImg4 }) {
    return (
        <div className={`cardwrapperUser ${classname}`} onClick={onClick}>
            <img className='profileImg' src={logo} alt="" />
            <div className="contentusercard mt-20">
                <div className="headingusercrad">
                    <h3>{colname}</h3>
                    <p className="text2">{tag}</p>
                </div>
                <div className="totaluser flex-between">
                    <div className='totalusertext'> {totalUser}<br />
                        <p className="text2">Total Users</p>
                    </div>
                    <div className="totaluserImg">
                        <img src={mentorImg} alt=""/>
                        <img src={mentorImg2} alt=""/>
                        <img src={mentorImg3} alt=""/>
                        <img src={mentorImg4} alt=""/>
                    </div>
                </div>
                {/* <div className="SalesUser">
                    <div className="countuser">
                        <span>
                            {sales}
                        </span>
                        <p className='mt-10 text2'>Sales</p>
                    </div>
                    <div className="countuser">
                        <span>
                            {amount}
                        </span>
                        <p className='mt-10 text2'>Amount</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default UserCard