import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import '../MyMentors/MyMentorsStyle.css'
import InputField2 from '../../components/InputField2/InputField2';
import './AllUser.css'
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import { clearState, createOrganization, superAdminData } from '../../reducer/superAdminSlice';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader/PageLoader';
import SuccessToast from "../../components/Toast/SuccessToast";
import ErrorToast from "../../components/Toast/ErrorToast";
import { isSubdomainExists } from '../../reducer/superAdminSlice';
import { TagsInput } from 'react-tag-input-component';

const AddOrganization = () => {

    const addOrgTemplate = {
        logoUrl: {
            name: "",
            url: ""
        },
        name: "",
        subDomain: "",
        credits: "",
        description: "",
        topics: "",
        admin: {
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: ""
        },
        permissions: {
            emails: false,
            sms: false,
            whatsapp: false
        }
    };

    const errorTemplate = {
        // logoUrl: "",
        name: "",
        subDomain: "",
        credits: "",
        description: "",
        topics: "",
        admin: {
            firstName: "",
            email: "",
            mobileNumber: ""
        },
        permissions: {
            emails: "",
            sms: "",
            whatsapp: ""
        }
    };

    const [topics, setTopics] = useState([]);
    const [addOrg, setAddOrg] = useState(addOrgTemplate);
    const [addOrgError, setAddOrgError] = useState(errorTemplate);
    const [isLoading, setIsLoading] = useState(false);

    //Toast states
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");

    const { isFetching, isSuccess, isSubdomain, isError, errorMessage } = useSelector(superAdminData);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleUploadProfile = (e) => {
        if (e.target.files.length > 0) {
          const files = e.target.files[0];
          setIsLoading(true);
          const data = new FormData();
    
          data.append("file", files);
          data.append("upload_preset", "qlm4yp8h");
          data.append("cloud_name", "df491ehfg");
    
          fetch("https://api.cloudinary.com/v1_1/df491ehfg/raw/upload", {
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
    
    
              setIsLoading(false);
              setAddOrg(prev => ({
                ...prev,
                logoUrl: {
                    name: res.original_filename,
                    url: res.secure_url
                }
              }));
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        }
      };

      const handleChange = (e) => {
        //   setAddOrgError(errorTemplate);
          const { name, value } = e.target;
          if(name === "subDomain") {
            setAddOrgError(prev => ({
                ...prev,
                subDomain: ""
              }))
          }
          else setAddOrgError(prev => ({
            ...errorTemplate,
            subDomain: prev["subDomain"]
          }));
        if(name === "mobileNumber" && (value?.length > 10 || value < 0)) return; 
        if(name === "credits" && (value < 0)) return; 
        if (name === "credits" && value) {
            setAddOrg(prev => ({
                ...prev,
                [name]: +value
            }));
            return;
        }
        setAddOrg(prev => ({
            ...prev,
            [name]: value
        }));
      };

      const handleAdminChange = (e) => {
        setAddOrgError(prev => ({
            ...prev,
            admin: errorTemplate["admin"]
        }));
        const { name, value } = e.target;
        if(name === "mobileNumber" && (value?.length > 10 || value < 0)) return; 
        setAddOrg(prev => ({
            ...prev,
            admin : {
                ...prev["admin"],
                [name]: value
            }
        }));
      };

      const handleSubdomainBlur = () => {
        if(isSubdomain) {
            setAddOrgError(prev => ({
                ...prev,
                subDomain: "Already exists! Please choose another one"
            }))
        };
        if(addOrg["subDomain"]) dispatch(isSubdomainExists(addOrg["subDomain"]));
      }

      const handlePermissionChange = (e) => {
        setAddOrgError(prev => ({
            ...prev,
            permissions: errorTemplate["permissions"]
        }));
        const { name } = e.target;
        setAddOrg(prev => ({
            ...prev,
            permissions: {
                ...prev["permissions"],
                [name]: !prev["permissions"][name]
            }
        }))
      };

      const handleDiscard = () => {
        setAddOrg(addOrgTemplate);
      }

      const validation = () => {
        for (const key in addOrgError) {
            if(key === "topics") continue;
            if(key === "permissions") continue;
            if(key === "admin") {
                for (const key1 in addOrgError[key]) {
                    if(key === "lastName") continue;
                    if(!addOrg[key][key1]) {
                        setAddOrgError(prev => ({
                            ...prev,
                            admin: {
                                [key1]: "This field is mandatory"
                            }
                        }));
                        return true;
                    }
                }
            }
            
            if(!addOrg[key]) {
                setAddOrgError(prev => ({
                    ...prev,
                    [key]: "This field is mandatory"
                }));
                return true;
            }
            if(topics.length === 0) {
                setAddOrgError(prev => ({
                    ...prev,
                    topics: "Please give atleast one topic!"
                }));
                return true;
            }
        }
        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!filter.test(addOrg["admin"]["email"])) {
            setAddOrgError((prev) => ({
            ...prev,
            admin: {
                email: "Please enter valid email address!"
            }
        }));
        return true;
        }
    }

        const handleSave = () => {
        if(!validation() && !addOrgError["subDomain"]) {
            const payload = {...addOrg};
            payload["logoUrl"] = addOrg["logoUrl"]["url"];
            payload["topics"] = topics;
            dispatch(createOrganization(payload));
        }
        else{
            setTimeout(() => {
              const ele = document.querySelector('.FormError');
              ele?.parentElement?.scrollIntoView({behavior: 'smooth'})
            }, 10)
          }
      };

      useEffect(() => {
        if(isSubdomain && addOrg["subDomain"]) {
            setAddOrgError(prev => ({
                ...prev,
                subDomain: "Already exists! Please choose another one"
            }))
        }
      },[isSubdomain])

      useEffect(() => {
        if(isSuccess) {
            setAddOrg(addOrgTemplate);
            setIsShowSuccess(true);
            setSuccessMessage("Success");
            navigate(-1);
            dispatch(clearState());
        }
      },[isSuccess]);

    useEffect(() => {
        if(isError) {
            // setAddOrg(addOrgTemplate);
            setIsShowError(true);
            setErrMessage(errorMessage);
            dispatch(clearState());
        }
    },[isError])

    return (
        <>
        {(isFetching || isLoading) && <PageLoader />}
        <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
        <div className='MyMentorsWrapper'>
            <div className="MymentorsHeading flex gap-10">
                <img src="/assets/img/BackArrow.png" alt="" className='pointer' onClick={() => { navigate('/allUser') }} />
                <div className="headingwrap">
                    <h2 className="heading-2">Add Organization</h2>
                    <p className='text2'>Back to viewing All Organizations</p>
                </div>
            </div>

            <div className="addMentorWrapper AddOrganizationwrapper">
                <div className="userProfileEdit flexbox">
                    <h4 className='lableHeading'>Logo</h4>

                    <div className="leftWrapperPrfile">
                        <div className="userProfileEditTab">
                            <label className="userProfile pointer" htmlFor="bgimg">
                                <img src={addOrg["logoUrl"]["url"] ? addOrg["logoUrl"]["url"] : "/assets/img/AddOrganization.png"} alt="" className='userimg' />
                                <input type="file" name="bgimg" id="bgimg" accept='image/*' onChange={handleUploadProfile} />
                                <img src="/assets/img/edit.png" alt="" className="editimg pointer Addmentorimg" />
                            </label>
                        </div>
                        <p className='text2 mt-5'>{addOrg["logoUrl"]["name"] ? addOrg["logoUrl"]["name"] : "File types allowed are: jpg, jpeg, png"}</p>
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Organization Name<span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 errorMessage={addOrgError["name"]} name={"name"} value={addOrg["name"]} onChange={handleChange} notImp={true} placeholder={'Name of the organization'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Subdomain<span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <div className='w-100'>
                        <InputField2 errorMessage={addOrgError["subDomain"]} name={"subDomain"} value={addOrg["subDomain"]} onChange={handleChange} notImp={true} placeholder={'Choose subdomain'} onBlur={handleSubdomainBlur} />
                        <p className='text2 mt-5'>{"URL will be : " + addOrg["subDomain"] + ".getprepped.in"}</p>
                        </div>
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    {/* <h4 className='lableHeading'>Credits <img src="/assets/img/info-circle.png" alt="" className='pointer' /></h4> */}
                    <h4 className='lableHeading'>Credits <span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 errorMessage={addOrgError["credits"]} type={"number"} name={"credits"} value={addOrg["credits"]} onChange={handleChange} notImp={true} placeholder={'Assign Credits'} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                    </div>
                </div>
                <div className="mulipleFormInputWrap flexbox">
                    {/* <h4 className='lableHeading'>Description <img src="/assets/img/info-circle.png" alt="" className='pointer' /></h4> */}
                    <h4 className='lableHeading'>Description <span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 errorMessage={addOrgError["description"]} name={"description"} value={addOrg["description"]} onChange={handleChange} notImp={true} placeholder={'Example: 1-1 Mentorship Programme'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
            <h4 className="lableHeading">Topics</h4>
            <div className="multipleFormInput flex-center TagsInputbar">
              <TagsInput
                value={topics}
                onChange={setTopics}
                placeHolder='Enter the topics for your organization'
              />
              {addOrgError["topics"] && <span className='FormError'>{addOrgError["topics"]}</span>}
            </div>
          </div>

                <div className="borderDotted borderFirst"> </div>
                <p className='text2 fiilldetailstext'>Fill in the details to create an Organization Login:</p>
                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Name <span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center inputalineerroe">
                        <InputField2 errorMessage={addOrgError["admin"]["firstName"]} name={"firstName"} value={addOrg["admin"]["firstName"]} onChange={handleAdminChange} notImp={true} placeholder={'Input first name'} />
                        <InputField2 errorMessage={addOrgError["admin"]["lastName"]} name={"lastName"} value={addOrg["admin"]["lastName"]} onChange={handleAdminChange} notImp={true} placeholder={'Input last name'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Email Id <span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 errorMessage={addOrgError["admin"]["email"]} name={"email"} value={addOrg["admin"]["email"]} onChange={handleAdminChange} notImp={true} placeholder={'Input email id'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Phone Number <span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 errorMessage={addOrgError["admin"]["mobileNumber"]} type={"number"} name={"mobileNumber"} value={addOrg["admin"]["mobileNumber"]} onChange={handleAdminChange} notImp={true} placeholder={'Input contact number'} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
                    </div>
                </div>
                <div className="borderDotted borderFirst"> </div>

                <div className="toggleMenuWrap flex-center">
                    <div className="toggleOptions">
                        <div className="toggleHeading">Emails</div>
                        <div className="toggleData">Can send emails to share any information</div>
                    </div>
                    <div className="toggleButton">
                        <ToggleSwitch name={"emails"} checked={addOrg["permissions"]["emails"]} onChange={handlePermissionChange} />
                    </div>
                </div>
                <div className="toggleMenuWrap flex-center">
                    <div className="toggleOptions">
                        <div className="toggleHeading">SMS</div>
                        <div className="toggleData">Can send sms to connect with any user </div>
                    </div>
                    <div className="toggleButton">
                        <ToggleSwitch name={"sms"} checked={addOrg["permissions"]["sms"]} onChange={handlePermissionChange}  />
                    </div>
                </div>
                <div className="toggleMenuWrap flex-center">
                    <div className="toggleOptions">
                        <div className="toggleHeading">Whatsapp</div>
                        <div className="toggleData">Enable whatsapp notifications for direct communication with the user</div>
                    </div>
                    <div className="toggleButton">
                        <ToggleSwitch name={"whatsapp"} checked={addOrg["permissions"]["whatsapp"]} onChange={handlePermissionChange}  />
                    </div>
                </div>
                <div className="FooterAdminPopup ">
                    <Button name={'Discard'} className={'buttontext GreyFillButton'} onClick={handleDiscard} />
                    <Button name={'Save Changes'} className="active buttontext BlueFillButton " onClick={handleSave} />
                </div>
            </div>

        </div>
        </>
    )
}


export default AddOrganization