import moment from 'moment';
import Button from '../Button/Button';
import DropdownCustom from '../DropdownCustom/DropdownCustom'
import './FiltersideviewStyle.css'
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const AdAllMenteeFilter = ({ isActive, handleClose, programOptions, cohortOptions, program, cohort, callDetails, status, handleProgramFilter, handleCohortFilter, handleCallDetailsFilter, handleStatusFilter, handleClearFilters, handleApplyFilters }) => {

    const ref3 = useRef(null);
    
    const callDetailsOptions = [
        {
            label: 'Completed Calls',
            value: 'completed'
        },
        {
            label: 'Cancelled Calls',
            value: 'cancelled'
        },
        {
            label: 'No Show (non availability) ',
            value: 'noshow'
        },
    ];

    const statusOptions = [
        {
            label: 'Active',
            value: "true"
        },
        {
            label: 'Inactive',
            value: "false"
        }
    ];

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref3.current && !ref3.current.contains(event.target)) {
                if (isActive) handleClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref3, isActive]);

    return (
        <div className={`filterpoupwrap  ${isActive && 'active'}`} >
            <div className='filtercontainer'>
                <div className={`filtersidescreenwrap`} ref={ref3}>
                    <div className="filterheader flex-between">
                        <h3 className='heading24'>Filters</h3>
                        <span className="close" onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <rect x="7.43359" y="7.4248" width="1" height="20" transform="rotate(-45 7.43359 7.4248)" fill="#171717" />
                                <rect width="1" height="20" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.2812 7.4248)" fill="#171717" />
                            </svg>
                        </span>
                    </div>
                    <div className="filterbodywrap mt-40">
                        <DropdownCustom placeholder={'Programme'} options={programOptions} src={'/assets/img/arrow-down2.png'} value={program} onChange={handleProgramFilter} />
                        <DropdownCustom placeholder={'Cohort'} options={cohortOptions} src={'/assets/img/arrow-down2.png'} value={cohort} onChange={handleCohortFilter} />
                        <DropdownCustom placeholder={'Call History'} options={callDetailsOptions} src={'/assets/img/arrow-down2.png'} value={callDetails} onChange={handleCallDetailsFilter} />
                        <DropdownCustom placeholder={'Status'} options={statusOptions} src={'/assets/img/arrow-down2.png'} value={status} onChange={handleStatusFilter} />
                    </div>

                    <div className="footerfilter mt-20">
                        <Button name={'Clear All'} transparent className={'buttontext GreyFillButton'} onClick={handleClearFilters} />
                        <Button noBorder={true} name={'Apply'} className="active buttontext BlueFillButton" onClick={handleApplyFilters} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default AdAllMenteeFilter