import React, { useState } from 'react'
import Button from '../../components/Button/Button';
import SlotPopup from '../../components/PopUp/SlotPopup/SlotPopup';
import SlotCard from '../../components/Slotcard/SlotCard'
import './MentorsProfileStyle.css';
import moment from 'moment/moment';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

function ViewSlots({ isActiveTab, mentorSlotDates, slots, onDateClick }) {

    const navigationNextRefNew = React.useRef(null);
    const navigationPrevRefNew = React.useRef(null);

    return (
        <div className={`ContainerTabForAllTabData MentorOverviewData ${isActiveTab && 'active'}`}>
            <div className="Headingforallheader">
                <div className="Activitiesheading  flex-between w-100">
                    <h2 className="heading-2">Call Details</h2>
                    {/* <Button name={'Add Slot'} src={'/assets/img/add-circle.png'} className="BlueFillButton buttontext" /> */}
                    {/* <SlotPopup isActive={isOpen} setIsActive={setIsOpen} /> */}
                </div>
            </div>

            <div className="border-btm  w-100"></div>

            <div className="bodyDataForallUserdata bordershort">
                <div className="BodyViewSlotWrapper">
                    <div className={`SwiperBodyWrappper mb-10 slideViewslotwrapper mt-30 }`}>
                        <div className="NextBtn swiperbtn" ref={navigationNextRefNew}>
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.46967 5.96967C8.76256 5.67678 9.23744 5.67678 9.53033 5.96967L15.5303 11.9697C15.8232 12.2626 15.8232 12.7374 15.5303 13.0303L9.53033 19.0303C9.23744 19.3232 8.76256 19.3232 8.46967 19.0303C8.17678 18.7374 8.17678 18.2626 8.46967 17.9697L13.9393 12.5L8.46967 7.03033C8.17678 6.73744 8.17678 6.26256 8.46967 5.96967Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                        <Swiper
                            slidesPerView={2.3}
                            modules={[Navigation]}
                            loop={false}
                            spaceBetween={20}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 5,
                                },
                            }}
                            onSwiper={(swiper) => {
                                // Delay execution for the refs to be defined
                                setTimeout(() => {
                                    // Override prevEl & nextEl now that refs are defined
                                    swiper.params.navigation.prevEl =
                                        navigationPrevRefNew.current;
                                    swiper.params.navigation.nextEl =
                                        navigationNextRefNew.current;

                                    // Re-init navigation
                                    swiper.navigation.destroy();
                                    swiper.navigation.init();
                                    swiper.navigation.update();
                                });
                            }}
                            navigation={{
                                // Both prevEl & nextEl are null at render so this does not work
                                prevEl: navigationPrevRefNew.current,
                                nextEl: navigationNextRefNew.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.navigation.nextEl = navigationNextRefNew.current;
                                swiper.navigation.prevEl = navigationPrevRefNew.current;
                            }}

                            className="mySwiper">

                            <div className="AdswiperWrappermain ">

                                {mentorSlotDates?.length > 0 ? mentorSlotDates?.map((item, index) => (
                                    <SwiperSlide>
                                        <SlotCard name={'one'} id={'slot' + index} className={'bgslotYellow'} slotCount={item?.slotsCount ?? "---"} day={item?.slotsDate ? moment(item?.slotsDate).format("ddd") : "---"} date={item?.slotsDate ? moment(item?.slotsDate).format("DD MMM") : "---"} onClick={() => onDateClick(item?.slotsDate)} />
                                    </SwiperSlide>
                                )) : "No slots available"}
                            </div>

                        </Swiper>

                        <div className="PreBtn swiperbtn" ref={navigationPrevRefNew}>
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.5303 5.96967C15.8232 6.26256 15.8232 6.73744 15.5303 7.03033L10.0607 12.5L15.5303 17.9697C15.8232 18.2626 15.8232 18.7374 15.5303 19.0303C15.2374 19.3232 14.7626 19.3232 14.4697 19.0303L8.46967 13.0303C8.17678 12.7374 8.17678 12.2626 8.46967 11.9697L14.4697 5.96967C14.7626 5.67678 15.2374 5.67678 15.5303 5.96967Z"
                                    fill="black"
                                />
                            </svg>
                        </div>

                    </div>
                </div>

                {slots?.length > 0 &&
                    <>
                        <div className="Dashborder"></div>

                        <div className="SlotAreaWrapperallCard SwiperBodyWrappper">

                            {slots?.map((item, index) => (
                                <div className="slotbookgrid">
                                    <label className="TimeSlotsCrad" htmlFor={'one' + index} key={index}>
                                        <input type="radio" name={'name'} id={'one' + index} />
                                        <div className='mainSlotBox'>
                                            {moment(item?.startTime).format("hh:mm a") ?? "---"}
                                        </div>
                                    </label>
                                </div>

                            ))}

                        </div>
                    </>
                }
            </div>
        </div>

    )
}

export default ViewSlots