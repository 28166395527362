import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './UsersStyle.css'
import SearchBar from '../../components/SearchBar/SearchBar'
import Button from '../../components/Button/Button';
import FilterPasscall from '../../components/PopUp/FilterPasscall/FilterPasscall';
import moment from 'moment/moment';

import ViewCallDetails from '../../components/PopUp/ViewCallDetails/ViewCallDetails'

const CallHistory = ({ isActive, isUpcomingCalls, setIsUpcomingCalls, upcomingCalls, pastCalls, totalUpcomingCalls, totalPastCalls, orgId, handleViewCallDetails, search, handleSearchChange, handleSearchPress, setFilterToggler, handleLoadMore }) => {

    // const DropdownValue = ['Applied Finance', 'Human Resource Management'];

    const navigate = useNavigate();

    return (
        <div className={`ContainerTabForAllTabData tabWrapper ${isActive && 'active'}`}>

            <div className="Headingforallheader">
                <div className="Activitiesheading  flex-between w-100">
                    <h2 className="heading-2">Call Details</h2>
                    <div className="btnRightsidesale ">
                        <Button name={'Upcoming'} onClick={() => setIsUpcomingCalls(true)} className={isUpcomingCalls && 'active'} />
                        <Button name={'Past'} onClick={() => setIsUpcomingCalls(false)} className={!isUpcomingCalls && 'active'} />
                    </div>
                </div>
            </div>

            <div className="border-btm  w-100"></div>

            {/* {Upcoming} */}
            <div className="bodyDataForallUserdata">
                <div className={`UsersCallProfileWrapper CallHistoryusersTab callDetailTab ${isUpcomingCalls && 'active'}`}>
                    <div className="FaqUsersCallDeatails mt-20">
                        <div className="FaqUserFiltter flex-between">
                            <SearchBar placeholder={'Search by Name'} value={search} onChange={handleSearchChange} onKeyDown={handleSearchPress} />
                            <div className="FilterButtonPast" >
                                <Button name={'Filters'} className={'FilterButton'} src="/assets/img/Filter list.png" onClick={() => setFilterToggler(true)} />
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr className='headingTabletr'>
                                    <th>Mentor</th>
                                    <th>Mentee</th>
                                    <th>Agenda</th>
                                    <th>Programme</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalUpcomingCalls?.length > 0 ? totalUpcomingCalls?.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="mentorProfilearea">
                                                <div className='ImaUserhistory'>

                                                    <img src={item?.mentor?.profileImg ? item?.mentor?.profileImg : "/assets/img/User.png"} alt="" className='pointer' onClick={() => { navigate(`/mentorprofile/${orgId}/${item?.mentor?.id}`) }} />
                                                </div>
                                                <div className='Namedetails'>
                                                    <h4 className='namelink pointer' onClick={() => { navigate(`/mentorprofile/${orgId}/${item?.mentor?.id}`) }}>{item?.mentor?.firstName ? `${item?.mentor?.firstName} ${item?.mentor?.lastName}` : "---"}</h4>
                                                    <p className="text2">{item?.mentor?.profession?.designation ?? "---"}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mentorProfilearea">
                                                <div className='ImaUserhistory'>
                                                    <img src={item?.mentee?.profileImg ? item?.mentee?.profileImg : "/assets/img/User.png"} className='pointer' alt="" onClick={() => { navigate(`/menteeprofile/${orgId}/${item?.mentee?.id}`) }} />
                                                </div>
                                                <div className='Namedetails'>
                                                    <h4 className='namelink pointer' onClick={() => { navigate(`/menteeprofile/${orgId}/${item?.mentee?.id}`) }}>{item?.mentee?.firstName ? `${item?.mentee?.firstName} ${item?.mentee?.lastName}` : "---"}</h4>
                                                    <p className="text2">{item?.mentee?.profession?.title ?? "---"}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {((item?.interviewAgenda?.agenda && item?.interviewAgenda?.agenda?.length > 20) || (!item?.interviewAgenda?.agenda && item?.agenda?.length > 20)) ? <div className='tableBox flex downtooltip'>
                                                <div className="textagendadata">
                                                    <h4 className='agendatextclap'>{item?.interviewAgenda?.agenda ? item?.interviewAgenda?.agenda : item?.agenda}</h4>
                                                </div>
                                                <div class="tooltipnew tooltip-scroll">
                                                    <img src="/assets/img/plus-circle.png" alt="circle_icon" />
                                                    <div class="toolwrappertext">
                                                        <span class="tooltip-text">{item?.interviewAgenda?.agenda ? item?.interviewAgenda?.agenda : item?.agenda}</span>
                                                    </div>
                                                </div>
                                            </div> : <div className='tableBox'>
                                                <h4>{item?.interviewAgenda?.agenda ? item?.interviewAgenda?.agenda : item?.agenda ? item?.agenda : "---"}</h4>
                                            </div>}
                                        </td>
                                        <td>
                                            <div className='mentorProfilearea'>
                                                <h4>{item?.program?.programName ?? "---"}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mentorProfilearea">
                                                <div className='Namedetails'>
                                                    <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("DD MMMM") : "---"}</h4>
                                                    <p className="text2">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("dddd") : "---"}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mentorProfilearea">
                                                <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("h:mm a") : "---"}</h4>
                                            </div>
                                        </td>
                                    </tr>
                                )) : "No calls yet"}

                            </tbody>
                        </table>
                    </div>
                </div>

                {/* {Past} */}

                <div className={`callsFaqqueWrap callDetailTab w-100 ${!isUpcomingCalls && 'active'}`}>
                    <div className="FaqUsersCallDeatails mt-20">
                        <div className="FaqUserFiltter flex-between">
                            <SearchBar placeholder={'Search by Name'} value={search} onChange={handleSearchChange} onKeyDown={handleSearchPress} />
                            <div className="FilterButtonPast " >
                                <Button name={'Filters'} className={'FilterButton'} src="/assets/img/Filter list.png" onClick={() => setFilterToggler(true)} />
                            </div>
                        </div>
                        <div className="ActivitiesWrapperFaq CallHistoryPastCall">
                            <table>
                                <thead>
                                    <tr className='headingTabletr'>
                                        <th>Mentor</th>
                                        <th>Mentee</th>
                                        <th>Programme</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalPastCalls?.length > 0 ? totalPastCalls?.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="tableBox mentorProfilearea">
                                                    <div className='Adminprofileimageuser'>
                                                        <div className='ImaUserhistory'>

                                                            <img src={item?.mentor?.profileImg ? item?.mentor?.profileImg : "/assets/img/User.png"} alt="" className='pointer ' />
                                                        </div>
                                                    </div>
                                                    <div className='Namedetails' onClick={() => { navigate(`/mentorprofile/${orgId}/${item?.mentor?.id}`) }}>
                                                        <h4 className='namelink'>{item?.mentor?.firstName ? `${item?.mentor?.firstName} ${item?.mentor?.lastName}` : "---"}</h4>
                                                        <p className="text2">{item?.mentor?.profession?.designation ?? "---"}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tableBox mentorProfilearea">
                                                    <div className='Adminprofileimageuser'>
                                                        <div className='ImaUserhistory'>
                                                            <img src={item?.mentee?.profileImg ? item?.mentee?.profileImg : "/assets/img/User.png"} alt="" className='pointer' />
                                                        </div>
                                                    </div>
                                                    <div className='Namedetails' onClick={() => { navigate(`/menteeprofile/${orgId}/${item?.mentee?.id}`) }}>
                                                        <h4 className='namelink'>{item?.mentee?.firstName ? `${item?.mentee?.firstName} ${item?.mentee?.lastName}` : "---"}</h4>
                                                        <p className="text2">{item?.mentee?.profession?.title ? item?.mentee?.profession?.title === "work" ? 'Working Professional' : 'Student' : "---"}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableBox mentorProfilearea'><h4>{item?.program?.programName ?? "---"}</h4></div>
                                            </td>
                                            <td>
                                                <div className='tableBox mentorProfilearea'>
                                                    <div className='mentorProfilearea'>
                                                        <h4 className={`capitalize ${item?.meetingStatus.toLowerCase()}`}>{item?.meetingStatus ? item?.meetingStatus === "pending" ? "Unattended" : item?.meetingStatus : "---"}</h4>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tableBox mentorProfilearea">
                                                    <div className='Namedetails'>
                                                        <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("DD MMMM") : "---"}</h4>
                                                        <p className="text2">{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("dddd") : "---"}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tableBox mentorProfilearea">
                                                    <h4>{item?.startTime ? moment(item?.startTime).utcOffset("-00:00").format("h:mm a") : "---"}</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tableBox">
                                                    <div className="tableBox">
                                                        <span className="tooltip calendar pointer" data-tooltip="View feedback" onClick={() => handleViewCallDetails(item?.id)}>
                                                            <img src="/assets/img/eye.png" alt="" />
                                                        </span>
                                                        {/* <span className='tooltip' data-tooltip="Delete">
                                                <img src="/assets/svg/trash.svg" alt="" />
                                            </span> */}
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )) : "No data available"}

                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
                {((upcomingCalls?.length >= 10 && isUpcomingCalls) || (pastCalls?.length >= 10 && !isUpcomingCalls)) && <div className="ViewMoreButton">
                    <Button name={'Load More'} className={'BlueFillButton buttontext'} onClick={handleLoadMore} />
                </div>}
            </div>
        </div>
    )
}

export default CallHistory