import React from 'react'
import './InputFieldStyle.css'

const InputField = ({type,id,placeholder,label, name, value, checked, onChange, onKeyDown, errorMessage,className,infoPointer, min, max}) => {
  return (
    <div className={`formGroup ${className}`}>
        <label htmlFor={id}>{label}
        <span class="impRed"> *</span> {infoPointer && <span className=''><img src="/assets/img/infoIcon.png" alt="" className='info pointer'/></span>}
        </label>
      <div className='errormessageinput'>
      <input type={type} id={id} placeholder={placeholder} value={value} checked={checked} name={name} onChange={onChange} onKeyDown={onKeyDown} min={min} max={max} />
        {errorMessage && <span className='FormError'>{errorMessage}</span>}
      </div>
    </div>
  )
}

export default InputField