import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileDropDownStyle.css';
function ProfileDropDown({ className, isActive, setIsActive }) {
    const navigate = useNavigate();
    const ref = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={`ProfileDropdownWrapper ${className} ${isActive && "active"}`} ref={ref}>
            <ul className='ListProfileDropdown'>
                {/* <li className='Border-btm pb-12' onClick={() => navigate('/EditProfile')}>My Profile</li> */}
                {/* <li>Guidelines</li>
                <li>Policies</li> */}
                {/* <li className='Border-btm pb-12' onClick={() => navigate('/EditProfile')}>Settings</li> */}
                <li className='logOut' onClick={() => { localStorage.clear(); window.location.reload() }} >Log Out</li>
            </ul>
        </div>
    )
}

export default ProfileDropDown