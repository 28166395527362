import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Layout/Header/Header";
import SideMenu from "./Layout/Menu/SideMenu/SideMenu";
import Dashboard from "./pages/dashboard/Dashboard";
import './App.css'
import AllUser from "./pages/AllUser/AllUser";
import User from "./pages/Users/Users";
import MenteesProfile from "./pages/MenteesProfile/MenteesProfile";
import MentorsProfile from "./pages/MentorsProfile/MentorsProfile";
import AdminProfile from "./pages/AdminProfile/AdminProfile";
import { AllNotifications } from "./pages/AllNotifications/AllNotifications";
import MyMentors from "./pages/MyMentors/MyMentors";
import AddMentor from "./pages/MyMentors/AddMentor";
import { useState } from "react";
import Authentication from "./pages/authentication/Authentication";
import { PrivateRoute } from "./helper/PrivateRoute";

import EditProfile from "./pages/EditProfile/EditProfile";
import AddOrganization from "./pages/AllUser/AddOrganization";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import LoginHeader from "./Layout/Header/LoginHeader";
import CustomCssWrite from "./pages/CustomCss/CustomCssWrite";
import Configure from "./pages/Configure/Configure";
import CreatePlan from "./pages/Configure/CreatePlan";

function App() {

  const [onClickMenu, setOnClickMenu] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className="appContainer">
      {pathname !== '/login' && <SideMenu onClickMenu={onClickMenu} setOnClickMenu={setOnClickMenu} />}
      <div className={`workSpace ${pathname === '/login' ? 'fullWidth' : ''}`}>
        {pathname !== '/login' ? <Header onClickMenu={onClickMenu} setOnClickMenu={setOnClickMenu} /> : <LoginHeader />}
        <div className={`mainWrapper ${pathname === '/login' ? 'fullHeight' : ''}`}>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/login" element={<Authentication />} />

            <Route path="/" element={<PrivateRoute />} >
              <Route path="*" element={<PageNotFound />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/allUser" element={<AllUser />} />
              <Route path="/users/:orgId" element={<User />} />
              <Route path="/writecss" element={<CustomCssWrite />} />
              <Route path="/adminprofile/:orgId/:userId" element={<AdminProfile />} />
              <Route path="/mentorprofile/:orgId/:userId" element={<MentorsProfile />} />
              <Route path="/menteeprofile/:orgId/:userId" element={<MenteesProfile />} />
              <Route path="/notifications" element={<AllNotifications />} />
              {/* <Route path="/mymentors" element={<MyMentors />} /> */}
              <Route path="/addmentor" element={<AddMentor />} />
              <Route path="/EditProfile" element={<EditProfile />} />
              <Route path="/AddOrganization" element={<AddOrganization />} />
              <Route path="/configure" element={<Configure />} />
              <Route path="/create-plan" element={<CreatePlan />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
