import React, { useEffect } from 'react';
import UserCard from '../../components/UserCard/UserCard';
import './AllUser.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrganizations, superAdminData } from '../../reducer/superAdminSlice';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PageLoader from "../../components/PageLoader/PageLoader";

function User() {
    // ---Navigate Masters union---
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isFetching, isSuccess, isError, errorMessage, allOrganizations } = useSelector(superAdminData);

    useEffect(() => {
        dispatch(getAllOrganizations());
      },[]);

    return (
        <>
        {isFetching && <PageLoader />}
        <section className='DashboardSection'>
            <div className="DashboardWrapper">
                <div class="MymentorsHeading flex-between">
                    <div class="headingwrap">
                        <h2 class="heading-2">All Organization</h2>
                        <p class="text2">The organizations using the main GetPrepped platform.</p>
                    </div>
                    <Button name={'Add Organization'} className={'BlueFillButton buttontext'}
                        onClick={() => { navigate('/AddOrganization') }} src={'/assets/img/add-circle.png'} />
                </div>

                <div className="DashboardOptionsCard allUserCards">
                    {allOrganizations?.length > 0 ? allOrganizations?.map((item , index) => (
                        <UserCard logo={item?.logoUrl ? item?.logoUrl : "/assets/img/user1.png"} colname={item?.name ? item?.name : "---"} tag={item?.description ? item?.description : "---"} totalUser={item?.userCount} classname={''}  
                        //  mentorImg={"/assets/img/Usercard4.png"} mentorImg2={"/assets/img/Users2.png"}  mentorImg3={"/assets/img/Users3.png"}  mentorImg4={"/assets/img/Users4.png"}
                         onClick={() => navigate(`/users/${item?.id}`)}
                        />
                    )) : "No organizations yet"}
                    {/* <UserCard logo={"/assets/img/Mu.png"} colname={'Master’s Union'} tag={'Management System'} totalUser={'12'} sales={'296'} amount={'$2276'} classname={'yellowTheme'} onClick={() => navigate('/users')}  mentorImg={"/assets/img/Usercard4.png"} mentorImg2={"/assets/img/Users2.png"}  mentorImg3={"/assets/img/Users3.png"}  mentorImg4={"/assets/img/Users4.png"}/>
                    <UserCard logo={"/assets/img/Lpu.png"} colname={'Herowide'} tag={'Management System'} totalUser={'12'} sales={'296'} amount={'$2276'} classname={'greenTheme'}  mentorImg={"/assets/img/Usercard4.png"} mentorImg2={"/assets/img/Users2.png"}  mentorImg3={"/assets/img/Users3.png"}  mentorImg4={"/assets/img/Users4.png"}/>
                    <UserCard logo={"/assets/img/Lpu.png"} colname={'Amity'} tag={'Management System'} totalUser={'12'} sales={'296'} amount={'$2276'} classname={'blueTheme'}  mentorImg={"/assets/img/Usercard4.png"} mentorImg2={"/assets/img/Users2.png"}  mentorImg3={"/assets/img/Users3.png"}  mentorImg4={"/assets/img/Users4.png"}/> */}
                </div>
            </div>
        </section >
        </>
    )
}

export default User