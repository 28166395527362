import React from 'react'
import './ProgressbarStyle.css';

function Progressbar({ count, answer }) {

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className='ProgressBarwrapper'>
            <div className='ProgressBarline'>
                <div className="progressCount" style={{ width: `${(+answer / +count) * 100}%` }}></div>
            </div>
            <div className="numberwrapperproress siderFlowWrap">
                {numbers.slice(0, count).map((item) => (
                    <div className='numberPoints '>{item}</div>
                ))}
            </div>
        </div>
    )
}

export default Progressbar