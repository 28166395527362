import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import "./CustomCssWrite.css";
import { saveOrgCss, getAllOrganizations, getOrganizationDetails, superAdminData, clearState } from "../../reducer/superAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader/PageLoader";
import SuccessToast from "../../components/Toast/SuccessToast";
import ErrorToast from "../../components/Toast/ErrorToast";

const CustomCssWrite = () => {

  const dispatch = useDispatch();

  const [orgData, setOrgData] = useState();
  const [css, setCss] = useState("");

  //Error states
  const [errorCss, setErrorCss]= useState("");

  //Toast states
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const { isFetching, isOrgDetailsFetching, isSuccess, isError, errorMessage, allOrganizations, organizationDetail} = useSelector(superAdminData);

  const handleSubmit = (e) => {
      const payload = {
        css: css,
        orgId: orgData,
      };
      if(organizationDetail?.orgData?.customCSS === css) {
        setErrorCss("No changes detected!");
      }
      else dispatch(saveOrgCss(payload));
    };
  
  useEffect(() => {
    dispatch(getAllOrganizations());
  }, []);

  useEffect(() => {
    if (orgData) {
      dispatch(getOrganizationDetails(orgData));
    }
  }, [orgData]);

  useEffect(() => {
    if(organizationDetail) {
      setCss(organizationDetail?.orgData?.customCSS);
    }
  },[organizationDetail])

  useEffect(() => {
    if(isSuccess) {
      setOrgData("");
      setCss("");
      setErrorCss("");
      setIsShowSuccess(true);
      setSuccessMessage("Success!");
      dispatch(clearState());
    }
  },[isSuccess])

  useEffect(() => {
    if(isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  },[isError]);

  return (
    <>
    {(isFetching || isOrgDetailsFetching) && <PageLoader />}
    <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
    <div className="customContainer">
      <div className="select-wrapper">
      <h4 className="home-page__title">
          Select Your Organization
        </h4>
        <select
          className="custom-select"
          onChange={(e) => setOrgData(e.target.value)}
          >
          <option value="" hidden >Select Organization</option>
          {allOrganizations?.length > 0 ?
            allOrganizations.map((org, key) => (
              <option key={key} value={org.id} selected={orgData === org.id} >
                {org.name}
              </option>
            )) : <option value="" disabled >No organization</option>}
        </select>
      </div>

      <div className="home-page">
        <h4 className="home-page__title">
          Write your custom CSS
        </h4>
        <div className="custom-textarea-container">
          <textarea
            className="custom-textarea"
            rows="10"
            columns="10"
            placeholder="Enter your text here"
            value={css}
            onChange={(e) => setCss(e.target.value)}
          ></textarea>
          {errorCss && <span className="FormError">{errorCss}</span>}
        </div>
        <button className="button-89" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
            </>
  );
};

export default CustomCssWrite;
