import React from "react";

const LoginHeader = () => {
  return (
    <div className="Header">
      <div className="flex-between headerafterLogin">
        <img src="/assets/svg/Logo.svg" alt="" class="Getprepped pointer" />
      </div>
    </div>
  );
};

export default LoginHeader;
