import React from 'react'

const PageNotFound = () => {
  return (
    <div>
      404 Page Not Found!
    </div>
  )
}

export default PageNotFound
