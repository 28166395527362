export const constants = {
    cohorts: [
      {
          label: "Cohort - 1",
          value: 1
      },
      {
          label: "Cohort - 2",
          value: 2
      },
      {
          label: "Cohort - 3",
          value: 3
      },
      {
          label: "Cohort - 4",
          value: 4
      },
      {
          label: "Cohort - 5",
          value: 5
      },
      {
          label: "Cohort - 6",
          value: 6
      },
      {
          label: "Cohort - 7",
          value: 7
      },
      {
          label: "Cohort - 8",
          value: 8
      },
      {
          label: "Cohort - 9",
          value: 9
      },
      {
          label: "Cohort - 10",
          value: 10
      },
      {
        label: "Cohort - 11",
        value: 11
    },
  ]
  };
