import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environments";

const apiRoute = "/api/superAdmin";

export const login = createAsyncThunk(
  "login/login",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
          environment.baseURL + 
          apiRoute +
          "/login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      
      if (response.status === 200) {
        localStorage.setItem("adminId", data.Data.id);
        localStorage.setItem("token", data.Token);

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "loginUser",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    isSubdomain: false,
    errorMessage: "",
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
        state.isFetching = true;
    });
    builder.addCase(login.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
    });
    builder.addCase(login.rejected, (state, {payload}) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.Message ?? "Something went wrong!";
    });
  },
//   extraReducers: (builder) => {
//     builder.addCase(incrementBy, (state, action) => {
//       return state + action.payload
//     })
//     builder.addCase(decrementBy, (state, action) => {
//       return state - action.payload
//     })
//   },
});


export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;