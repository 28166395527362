import React, { useState } from 'react'
import './FilterPasscallStyle.css'
import InputField from '../../InputField/InputField'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import Button from '../../Button/Button';
import moment from 'moment';

function FilterPasscall({isActive, handleClose, isPastCalls, programs, handleProgramFilter, status, date, handleDateFilter, handleStatusFilter,handleApplyFilters}) {

    // const [active, setActive] = useState(false);
    // const DropdownValue = ['Applied Finance', 'Human Resource Management'];
    return (

        <div className={`filterPopupPastcall filterPopup ${isActive && 'active'}`}>
            <div className={`PopupFilterCallWrapper`}>
                <div className="FilterBodyPasscall">
                    <div className="popupHeader">
                        <span className="close"><img src="/assets/svg/close.svg" alt="" onClick={handleClose} /></span>
                    </div>
                    <div className="headingwrapper">
                        <h3>Call Details</h3>
                    </div>
                    <div className="ContentBodyWrapperPastCall">
                        <div className="InputFieldsPassCall">
                        <InputField type={'date'} value={date} onChange={handleDateFilter} min={!isPastCalls && moment().format("YYYY-MM-DD")} max={isPastCalls && moment().format("YYYY-MM-DD")} />
                        </div>
                        <div className="rightsidebtnCallDetails f-items-center">
                        <select className="filterDrop" name='pid' onChange={handleProgramFilter} >
                                <option className='menuList' defaultChecked value={""} >Program</option>
                                {/* <option className='menuList' value={""}>All</option> */}
                                    {programs?.map((item) => (
                                        <option className='menuList' value={item?.value}>{item?.name}</option>
                                    ))}
                            </select>
                        </div>
                        {isPastCalls && <div className="rightsidebtnCallDetails f-items-center">
                            <select className="filterDrop" name='pid' onChange={handleStatusFilter} >
                                <option className='menuList' defaultChecked value={""} >Status</option>
                                {/* <option className='menuList' value={""}>All</option> */}
                                <option className='menuList' selected={status === "pending"} value={"pending"}>Unattended</option>
                                <option className='menuList' selected={status === "completed"} value={"completed"}>Completed</option>
                                <option className='menuList' selected={status === "rescheduled"} value={"rescheduled"}>Rescheduled</option>
                                <option className='menuList' selected={status === "cancelled"} value={"cancelled"}>Cancelled</option>
                            </select>
                        </div>}
                        <div className="FilterButtonPassCall w-100">
                        <Button name={'Show Results'} className=" buttontext BlueFillButton w-100" onClick={handleApplyFilters} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterPasscall