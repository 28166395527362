import React, { useEffect, useState } from 'react'
import './EditProfileStyle.css'
import { useNavigate } from 'react-router-dom';
import OverView from './OverView';
import Settings from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import { getSuperAdminDetails, superAdminData } from '../../reducer/superAdminSlice';
import PageLoader from '../../components/PageLoader/PageLoader';
const EditProfile = () => {
    const [TabSlotProfile, setTabSlotProfile] = useState(1);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isFetching, isSuccess, isUserAddedSuccess, isError, errorMessage, superAdmin} = useSelector(superAdminData);

    useEffect(() => {
        dispatch(getSuperAdminDetails());
    },[])

    return (
        <>
        {isFetching && <PageLoader />}
        <section className='DashboardSection UsersSectionwrap'>
            <div className="DashboardWrapper">
                <div className="headingwrap">
                    <h2 className='heading-2'>My Profile</h2>
                    <p className="text2">View Profile Details</p>
                </div>
                <div className="flex-col userWrapperprofile MentorMainProfileWrapper">
                    <div className="profilewrapper w-100 EditProfileUsers">
                        <div className="profileDetailsview MentorsProfilewrapper">
                            <div className='ProfileImage'>
                                <img src="/assets/img/ProfileImage.png" alt="" />
                            </div>
                            <div className="ProfileDetailsarea">
                                <div className="headingwrap">
                                    <div className="headingorg">
                                        <h2 className='heading-2'>Hemant Pahwa</h2>
                                        <a href="#">
                                            <span>Linkedin <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.9165 1.4585L1.08317 7.29183" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.9165 5.73766V1.4585H2.63734" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="PsnlDetailsMentors MyProfileWrapper">
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/usermentor.png" alt="" />
                                            </span>
                                            <p className="text2">Super Admin</p>
                                        </div>
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/buliding.png" alt="" />
                                            </span>
                                            <p className="text2">hemantpahwa33@gmail.com</p>
                                        </div>
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/buliding.png" alt="" />
                                            </span>
                                            <p className="text2">+91 9876 543 210</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="MenusTabWrapper w-100">
                        <ul className='flex gap-30 items-center'>
                            <li onClick={() => setTabSlotProfile(1)} className={TabSlotProfile === 1 && 'active'}>Overview</li>
                            <li onClick={() => setTabSlotProfile(2)} className={TabSlotProfile === 2 && 'active'}>Settings</li>
                        </ul>
                    </div>

                </div>

            </div>

            <OverView isActiveTab={TabSlotProfile === 1 && 'active'} />
            <Settings isActiveTab={TabSlotProfile === 2 && 'active'} />

            {/* <PopFeedBack /> */}

        </section>
        </>
    )
}

export default EditProfile