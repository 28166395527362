import React from 'react';
import './FaqStyle.css';
import Collapsible from 'react-collapsible';
function Faq({ heading, text, list1, list2, list3, icon, className, time, classname, onClick }) {
    return (
        <div className={`FaqTimeline ${classname}`} onClick={onClick}>
            <span className='TimeFaq'>{time}</span>
            <Collapsible trigger={heading} transitionTime={'200'}>
                <p className="text2">{text}</p>
                <ul className='flex-col AnswerFaq'>
                    <li><span>{icon}</span> {list1} </li>
                    <li><span>{icon}</span> {list2} </li>
                    <li><span>{icon}</span> {list3} </li>
                </ul>
            </Collapsible>
        </div>
    )
}

export default Faq