import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import ProfileCard from '../../components/ProfileCard/ProfileCard';
import '../MentorsProfile/MentorsProfileStyle.css';
import './AdminProfileStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getParticularUser, superAdminData } from '../../reducer/superAdminSlice';
import Button from '../../components/Button/Button';
import PageLoader from '../../components/PageLoader/PageLoader';
function AdminProfile() {

  const [TabSlotProfile, setTabSlotProfile] = useState(1);

  const { isFetching, isError, errorMessage, particularUser } = useSelector(superAdminData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId, userId } = useParams();

  useEffect(() => {
    dispatch(getParticularUser({ orgId, user: "admin", userId }));
  }, [])

  return (
    <>
      {isFetching && <PageLoader />}
      <section className='DashboardSection UsersSectionwrap'>
        <div className="DashboardWrapper">
          <div className="headingwrap">
            <h2 className='heading-2'>Profile Overview</h2>
            <p className="text2"><span className='linktabAtive' onClick={() => { navigate('/allUser') }}> All Users </span>/ Admin Profile</p>
          </div>
          <div className="flex-col userWrapperprofile MentorMainProfileWrapper">
            <div className="profilewrapper w-100 ">
              <div className="profileDetailsview MentorsProfilewrapper adminProfilemain">
                <div className='Imageboxall'>
                  <img src={particularUser?.profileImg ? particularUser?.profileImg : "/assets/img/User.png"} alt="" />
                </div>
                <div className="ProfileDetailsarea">
                  <div className="headingwrap">
                    <div className="headingorg">
                      <h2 className='heading-2'>{particularUser?.firstName ? `${particularUser?.firstName} ${particularUser?.lastName}` : "---"}</h2>
                      {particularUser?.linkedin && <a href={particularUser?.linkedin} target='_blank' >
                        <span>Linkedin <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.9165 1.4585L1.08317 7.29183" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.9165 5.73766V1.4585H2.63734" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </span>
                      </a>}
                    </div>
                    <div className="PsnlDetailsMentors">
                      <div className="MentorSubDetails f-items-center">
                        <span className="icontools flex-center">
                          <img src="/assets/img/contact/usermentor.png" alt="" />
                        </span>
                        <p className="text2">{particularUser?.profession?.designation ? particularUser?.profession?.designation : "---"}, {particularUser?.profession?.company ? particularUser?.profession?.company : "---"}</p>
                      </div>
                      <div className="MentorSubDetails f-items-center">
                        <span className="icontools flex-center">
                          <img src="/assets/img/contact/buliding.png" alt="" />
                        </span>
                        <p className="text2">{particularUser?.email ? particularUser?.email : "---"}</p>
                      </div>
                      <div className="MentorSubDetails f-items-center">
                        <span className="icontools flex-center">
                          <img src="/assets/img/contact/buliding.png" alt="" />
                        </span>
                        <p className="text2">{particularUser?.mobileNumber ? particularUser?.mobileNumber : "---"}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="MenusTabWrapper w-100">
              <ul className='flex gap-30 items-center'>
                <li onClick={() => setTabSlotProfile(1)} className={TabSlotProfile === 1 && 'active'}>Overview</li>
                {/* <li onClick={() => setTabSlotProfile(2)} className={TabSlotProfile === 2 && 'active'}>Activity</li> */}
              </ul>
            </div>

          </div>

        </div>

        {/* {Overview} */}

        <div className={`ContainerTabForAllTabData MentorOverviewData ${TabSlotProfile === 1 && 'active'}`}>

          <div className="Headingforallheader">
            <div className="Activitiesheading  flex-between w-100">
              <h2 className="heading-2">Call Details</h2>
            </div>
          </div>

          <div className="border-btm  w-100"></div>

          <div className="bodyDataForallUserdata">
            <div className="Bodymentordatawrap OverViewProfilePage">
              <div className="dataWrapperMenteeListitems">
                <div className="leftheadingListMentee">
                  <div className="leftHeadingmenteelist">First Name</div>
                  <div className="leftHeadingmenteelist">Last Name</div>
                  <div className="leftHeadingmenteelist">Designation</div>
                  <div className="leftHeadingmenteelist">Phone Number	</div>
                  <div className="leftHeadingmenteelist">Email</div>
                  <div className="leftHeadingmenteelist">Linkedin</div>
                </div>

                <div className="RightListDataMenteeProfile">
                  <div className="rightdatamenteelist">{particularUser?.firstName ? particularUser?.firstName : "---"}</div>
                  <div className="rightdatamenteelist">{particularUser?.lastName ? particularUser?.lastName : "---"}</div>
                  <div className="rightdatamenteelist">{particularUser?.profession?.designation ? particularUser?.profession?.designation : "---"}</div>
                  <div className="rightdatamenteelist">{particularUser?.mobileNumber ? particularUser?.mobileNumber : "---"}</div>
                  <div className="rightdatamenteelist">{particularUser?.email ? particularUser?.email : "---"}</div>
                  <div className="rightdatamenteelist">
                    {particularUser?.linkedin ? <Link className='SocialLink' target='_blank' to={particularUser?.linkedin} onClick={(e) => !particularUser?.linkedin && e.preventDefault()}>
                      {particularUser?.linkedin ? particularUser?.linkedin : "---"}
                    </Link> :
                      <span className='rightdatamenteelist'>{"---"}</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {Activity} */}

        <div className={`ContainerTabForAllTabData MentorOverviewData ${TabSlotProfile === 2 && 'active'}`}>

          <div className="Headingforallheader">
            <div className="Activitiesheading  flex-between w-100">
              <h2 className="heading-2">Task List</h2>
              <div className="btnRightsidesale">
                <Button name={'Today'} className="active" />
                <Button name={'Week'} />
                <Button name={'Month'} />
                <Button name={'2023'} />
              </div>
            </div>
          </div>

          <div className="border-btm  w-100"></div>

          {/* Body */}
          <div className="bodyDataForallUserdata">
            <div className="Bodymentordatawrap ActivityFaqPl">
              <div className="TaskListWrapper">
                <span className="TimeFaq">14:30</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Added 3 Mentors for Financial Planning
                  </div>
                  <div className="AnswerTaskListData f-items-center">
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Nimisha Shrivastava</h3>
                        <p className="text2">Senior Finance Manager</p>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Hemant Gangwani</h3>
                        <p className="text2">Assistant Manager</p>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Jayshree Manchanda</h3>
                        <p className="text2">Technical Analyst</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">18:10</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Created a batch for students referring Applied Finance
                  </div>
                  <div className="AnswerTextTaskList f-items-center">
                    <p className="text2">68 Students</p>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">10:15</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Added 3 Mentors for Financial Planning
                  </div>
                  <div className="AnswerTaskListData f-items-center">
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Mu.png" alt="" className='mulogo' />
                      <div className="nameDataTakslist">
                        <h3>Nimisha Shrivastava</h3>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Mu.png" alt="" className='mulogo' />
                      <div className="nameDataTakslist">
                        <h3>Hemant Gangwani</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">12:03</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Created a batch for students referring Applied Finance
                  </div>
                  <div className="AnswerTextTaskList f-items-center">
                    <p className="text2">68 Students</p>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">16:21</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Added 3 Mentors for Financial Planning
                  </div>
                  <div className="AnswerTaskListData f-items-center">
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Nimisha Shrivastava</h3>
                        <p className="text2">Senior Finance Manager</p>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Hemant Gangwani</h3>
                        <p className="text2">Assistant Manager</p>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Jayshree Manchanda</h3>
                        <p className="text2">Technical Analyst</p>
                      </div>
                    </div>
                    <div className="ProfileDatatasklist f-items-center gap-10">
                      <img src="/assets/img/Usercard3.png" alt="" />
                      <div className="nameDataTakslist">
                        <h3>Jayshree Manchanda</h3>
                        <p className="text2">Technical Analyst</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">12:03</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Created a batch for students referring Applied Finance
                  </div>
                  <div className="AnswerTextTaskList f-items-center">
                    <p className="text2">68 Students</p>
                  </div>
                </div>
              </div>

              <div className="TaskListWrapper">
                <span className="TimeFaq">16:21</span>
                <div className="TaskListDataWrap">
                  <div className="headingTaskList">
                    Created a batch for students referring Applied Finance
                  </div>
                  {/* <div className="AnswerTextTaskList f-items-center">
              <p className="text2">68 Students</p>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default AdminProfile