import React from 'react'
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import InputField2 from '../../components/InputField2/InputField2';

const Setting = ({ isActive, userAccess, onUserAccessChange, onUserAccessDiscard, onUserAccessSave, permissions, onPermissionsChange, onPermissionsDiscard, onPermissionsSave }) => {

  return (
    <div className={`wrapperBox tabWrapper ${isActive && 'active'}`}>
      <div className="bottomTabWrapper mt-20">
        <div className="boxHeading">Manage User Access</div>
        <div className="boxBody">
          <div className="userAccessWrap flex-center">
            <InputField2 type={'number'} placeholder={'Enter Value'} id={'AdminCount'} label={'Admin Count'} value={userAccess["totalAdmin"]} onChange={onUserAccessChange} name={"totalAdmin"} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
            <InputField2 type={'number'} placeholder={'Enter Value'} id={'AdminCount'} label={'Total Mentors'} value={userAccess["totalMentor"]} onChange={onUserAccessChange} name={"totalMentor"} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
            <InputField2 type={'number'} placeholder={'Enter Value'} id={'AdminCount'} label={'Mentee Logins'} value={userAccess["totalMentee"]} onChange={onUserAccessChange} name={"totalMentee"} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} />
          </div>
          <div className="manageText text2">Please limit the number of users allowed to access the organizational services. The input fields show the current allowed number of users respectively.</div>
        </div>
        <div className="boxBottom flex flexEnd">
          <Button className={'GreyFillButton buttontext'} name={'Discard'} onClick={onUserAccessDiscard} />
          <Button className={'BlueFillButton buttontext'} name={'Save Changes'} onClick={onUserAccessSave} />
        </div>
      </div>

      <div className="bottomTabWrapper mt-20">
        <div className="boxHeading">Permissions List</div>
        <div className="boxBody">

          <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">Enable Internal Mentors Visibility</div>
              <div className="toggleData">Provide access to existing Masters pool from our organization for 1:1 Mentorship</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={"internalMentors"} checked={permissions["internalMentors"]} onChange={onPermissionsChange} />
            </div>
          </div>

          {/* <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">Logo</div>
              <div className="toggleData">Can upload logo of the organization</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={""} />
            </div>
          </div> */}

          <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">Color Scheme</div>
              <div className="toggleData">Enable color updates with respect to the organization</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={"colorScheme"} checked={permissions["colorScheme"]} onChange={onPermissionsChange} />
            </div>
          </div>

          <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">Emails</div>
              <div className="toggleData">Can send emails to share any information</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={"emails"} checked={permissions["emails"]} onChange={onPermissionsChange} />
            </div>
          </div>

          <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">SMS</div>
              <div className="toggleData">Can send sms to connect with any user</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={"sms"} checked={permissions["sms"]} onChange={onPermissionsChange} />
            </div>
          </div>

          <div className="toggleMenuWrap flex-center">
            <div className="toggleOptions">
              <div className="toggleHeading">Whatsapp</div>
              <div className="toggleData">Enable whatsapp notifications for direct communication with the user</div>
            </div>
            <div className="toggleButton">
              <ToggleSwitch name={"whatsapp"} checked={permissions["whatsapp"]} onChange={onPermissionsChange} />
            </div>
          </div>

        </div>
        <div className="boxBottom flex flexEnd">
          <Button className={'GreyFillButton buttontext'} name={'Discard'} onClick={onPermissionsDiscard} />
          <Button className={'BlueFillButton buttontext'} name={'Save Changes'} onClick={onPermissionsSave} />
        </div>
      </div>
    </div>
  )
}

export default Setting