import React, { useState } from 'react'
import InputField2 from '../../components/InputField2/InputField2';
import Button from '../../components/Button/Button';
import './EditProfileStyle.css'
const Settings = ({ isActiveTab }) => {
    const [changePassword, setChangePassword] = useState(1);
    return (

        <div className={`MentorOverviewData ${isActiveTab && 'active'}`}>
            {/* <div className={`userWrapperprofile EditProfileWrapper `}>
                <div className="border-btm  w-100">
                    <div className="Activitiesheading  flex-between w-100">
                        <h2 className="heading-2">Call Details</h2>
                    </div>
                </div>

                <div className="userProfileEdit flexbox mt-40">
                    <h4 className='lableHeading'>Avatar</h4>

                    <div className="leftWrapperPrfile">
                        <div className="userProfileEditTab">
                            <label className="userProfile pointer" htmlFor="bgimg">
                                <img src="/assets/img/user1.png" alt="" className='userimg' />
                                <input type="file" name="bgimg" id="bgimg" accept='image/*' />
                                <img src="/assets/img/edit.png" alt="" className="editimg pointer" />
                            </label>
                        </div>
                        <p className='mt-10 text2'>File types allowed are: jpg, jpeg, png</p>
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Full Name<span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center inputalineerroe">
                        <InputField2 notImp={true} placeholder={'First Name'} />
                        <InputField2 notImp={true} placeholder={'Last Name'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Designation<span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 notImp={true} placeholder={'Input designation'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Company<span className="impRed"> *</span></h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 notImp={true} placeholder={'Input company name'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Phone Number</h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 notImp={true} placeholder={'Input contact number'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Email</h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 notImp={true} placeholder={'Input email id'} />
                    </div>
                </div>

                <div className="mulipleFormInputWrap flexbox">
                    <h4 className='lableHeading'>Linkedin Profile</h4>
                    <div className="multipleFormInput flex-center">
                        <InputField2 notImp={true} placeholder={'Input linkedin profile link'} />
                    </div>
                </div>

                <div className="FooterAdminPopup ">
                    <Button name={'Discard'} className={'buttontext GreyFillButton'} />
                    <Button name={'Save Changes'} className="active buttontext BlueFillButton" />
                </div>
            </div> */}

            <div className={`addMentorWrapper mt-30 passcodeWrapper ${changePassword === 1 && 'showIt'}`}>
                <div className="border-btm  w-100">
                    <div className="Activitiesheading w-100">
                        <h2 className="heading-2">Change Password</h2>
                    </div>
                </div>
                <div className="Password flexbox mt-30">
                    <h4 className='lableHeading'><b>Password <br /> <span className='text-grey6'>*********</span> </b></h4>
                    <Button className={'BlueFillButton buttontext'} name={'Reset Password'} onClick={() => setChangePassword(2)} />
                </div>
            </div>

            <div className={`addMentorWrapper passcodeWrapper mt-30 ${changePassword === 2 && 'showIt'}`}>
                <div className="border-btm  w-100">
                    <div className="Activitiesheading w-100">
                        <h2 className="heading-2">Change Password</h2>
                    </div>
                </div>
                <div className="flexbox passwordchange">
                    <div className="mulipleFormInputWrap">
                        <h4 className='lableHeading mb-10'>Current Password</h4>
                        <div className="multipleFormInput flex-center">
                            <InputField2 notImp={true} placeholder={'******'} />
                        </div>
                    </div>
                    <div className="mulipleFormInputWrap">
                        <h4 className='lableHeading mb-10'>Current Password</h4>
                        <div className="multipleFormInput flex-center">
                            <InputField2 notImp={true} placeholder={'******'} />
                        </div>
                    </div>
                    <div className="mulipleFormInputWrap">
                        <h4 className='lableHeading mb-10'>Current Password</h4>
                        <div className="multipleFormInput flex-center">
                            <InputField2 notImp={true} placeholder={'******'} />
                        </div>
                    </div>
                </div>
                <div className="FooterDuleBtn flex mt-20">
                    <Button className={'GreyFillButton BlueFillButton buttontext'} name={'Discard'} onClick={() => setChangePassword(1)} />
                    <Button className={'BlueFillButton buttontext'} name={'Save Changes'} onClick={() => setChangePassword(1)} />
                </div>
            </div>
        </div>



    )
}

export default Settings