import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button'
import InputField from '../../components/InputField/InputField'
// import PageLoader from '../../components/PageLoader/PageLoader';
// import ErrorToast from '../../components/Toast/ErrorToast';
// import SuccessToast from '../../components/Toast/SuccessToast';
import { userSelector } from '../../reducer/userSlice';
import InputField2 from '../../components/InputField2/InputField2';

const ForgotPassword = ({ setPageToggler }) => {

  //Toast states
  // const [isShowError, setIsShowError] = useState(false);
  // const [errMessage, setErrMessage] = useState("");
  // const [isShowSuccess, setIsShowSuccess] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");

  //Validation state
  const [emailValidation, setEmailValidation] = useState(false);

  const [registeredEmail, setRegisteredEmail] = useState("");
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, errorMessage, LoginData } =
    useSelector(userSelector);

  const handleEmailChange = (e) => {
    setEmailValidation(false);
    setRegisteredEmail(e.target.value);
  };

  const handleSendLink = (e) => {
    e.preventDefault();
    const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!registeredEmail) {
      setEmailValidation(true);
    } else if (!filter.test(registeredEmail)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
      // dispatch(forgotPassword(registeredEmail));
    }
  };

  // useEffect(() => {
  //   if(isSuccess) {
  //     setIsShowSuccess(true);
  //     setSuccessMessage("Success! Please check your email for reset link")
  //     dispatch(clearState());
  //   }
  // },[isSuccess]);

  // useEffect(() => {
  //   if(isError) {
  //     setIsShowError(true);
  //     setErrMessage(errorMessage);
  //     dispatch(clearState());
  //   }
  // },[isError])

  return (
    <>
    {/* {isFetching && <PageLoader />} */}
    {/* <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      /> */}
    <div className="componentForget">

      <div className="formWrapper">
        <div className="BlueTag mb-10">Registered Account</div>
        <h2 className="heading-2 mb-20">Forgot Password</h2>
        <InputField2 label={'Email'} type={'email'} className={'FormInput'} value={registeredEmail} onChange={handleEmailChange} placeholder={'Enter your registered Email'} id={'ForgetPass'} isError={emailValidation} erroMessage={"Please enter valid email address"} labelName={'Registered Email'} />
      </div>

      <div className="buttonWrapper">
        <Button onClick={handleSendLink} className={'blackButton'} name={'Send Link'} />
        <Button onClick={() => setPageToggler(0)} className={'whiteButton mt-10'} name={'Back to Login'} />
      </div>

      <div className="policyWrapper mt-20">
        <p className="policyText">Copyright @getprepped</p>
        |
        <p className="policyText">Privacy Policy</p>
      </div>
    </div>
    </>
  )
}

export default ForgotPassword