import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import CallHistoryMentees from './CallHistoryMentees';
import OverviewMentees from './OverviewMentees';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackAnswers, getParticularUser, getPastInterviewDetails, getUpcomingInterviewDetails } from '../../reducer/superAdminSlice';
import { superAdminData } from '../../reducer/superAdminSlice';
import PageLoader from '../../components/PageLoader/PageLoader';
import MenteeFeedbackPopup from '../../components/PopUp/MenteeFeedbackPopup/MenteeFeedbackPopup';

function MenteesProfile() {
    
    const [TabSlotProfile, setTabSlotProfile] = useState(1);
    const [isUpcomingCalls, setIsUpcomingCalls] = useState(true);
    const [feedbackPopupToggler, setFeedbackPopupToggler] = useState(false);
    const [menteeFeedback, setMenteeFeedback] = useState({});
    const [mentorFeedback, setMentorFeedback] = useState({});

    //Load more states
    const [index, setIndex] = useState(1);
    const [totalUpcomingCalls, setTotalUpcomingCalls] = useState([]);
    const [totalPastCalls, setTotalPastCalls] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orgId, userId } = useParams();

    const { isFetching, isSuccess, isError, errorMessage, particularUser, userUpcomingCalls, userPastCalls, feedbackAnswers } = useSelector(superAdminData);

    //Load more functions
    const handleLoadMore = () => {
        setIndex(prev => prev + 1);
        if(isUpcomingCalls) dispatch(getUpcomingInterviewDetails({user: "mentee", userId, index: index+1}))
        else dispatch(getPastInterviewDetails({user: "mentee", userId, index: index+1}));
    };

    useEffect(() => {
        dispatch(getParticularUser({orgId, user: "mentee", userId}))
    },[]);

    //Load more useEffect
    useEffect(() => {
        if(index === 1 && userUpcomingCalls?.length > 0 ) setTotalUpcomingCalls(userUpcomingCalls);
        if(index === 1 && userPastCalls?.length > 0) setTotalPastCalls(userPastCalls);
        if(index !== 1 && userUpcomingCalls?.length > 0 ) setTotalUpcomingCalls(prev => [...prev,...userUpcomingCalls]);
        if(index !== 1 && userPastCalls?.length > 0) setTotalPastCalls(prev => [...prev,...userPastCalls]);
    },[userUpcomingCalls,userPastCalls]);

    useEffect(() => {
        setTotalPastCalls([]);
        setTotalUpcomingCalls([]);
        setIndex(1);
        if(TabSlotProfile === 2) {
           if(isUpcomingCalls) dispatch(getUpcomingInterviewDetails({orgId, userId, user: "mentee"}))
           else dispatch(getPastInterviewDetails({orgId, userId, user: "mentee"}));
        } 
    },[TabSlotProfile, isUpcomingCalls]);

    const handleViewFeedback = (bookingId) => {
        setFeedbackPopupToggler(true);
        dispatch(getFeedbackAnswers(bookingId))
    };

    useEffect(() => {
        if(feedbackAnswers) {
            const arrMentee = feedbackAnswers?.feedbackAnswers?.filter(item => item.menteeId);
            const arrMentor = feedbackAnswers?.feedbackAnswers?.filter(item => item.mentorId);
            if(arrMentee?.length > 0) setMenteeFeedback(...arrMentee);
            if(arrMentor?.length > 0) setMentorFeedback(...arrMentor);
        }
    },[feedbackAnswers]);

    const handleFeedbackClose = () => {
        setMenteeFeedback({});
        setMentorFeedback({});
        setFeedbackPopupToggler(false);
    };

    return (
        <>
        {isFetching && <PageLoader />}
        <MenteeFeedbackPopup isActive={feedbackPopupToggler} setIsActive={setFeedbackPopupToggler} menteeFeedback={menteeFeedback} mentorFeedback={mentorFeedback} onFeedbackClose={handleFeedbackClose} />

        <section className='DashboardSection UsersSectionwrap'>
            <div className="DashboardWrapper">
                <div className="headingwrap">
                    <h2 className='heading-2'>Profile Overview</h2>
                    <p className="text2"><span className='linktabAtive' onClick={() => { navigate(`/users/${orgId}`) }}>All Mentees</span>  /  Account</p>
                </div>
                <div className="flex-col userWrapperprofile MentorMainProfileWrapper">
                    <div className="profilewrapper w-100 ">
                        <div className="profileDetailsview MentorsProfilewrapper">
                            <div className='Imageboxall'>
                                <img src={particularUser?.profileImg ? particularUser?.profileImg : "/assets/img/contact/User1.png"}  alt="" />
                            </div>
                            <div className="ProfileDetailsarea">
                                <div className="headingwrap">
                                    <div className="headingorg">
                                        <h2 className='heading-2'>{particularUser?.firstName ? `${particularUser?.firstName} ${particularUser?.lastName}` : "---"}</h2>
                                        {particularUser?.linkedin && <a target='_blank' href={particularUser?.linkedin}>
                                            <span>Linkedin <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.9165 1.4585L1.08317 7.29183" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.9165 5.73766V1.4585H2.63734" stroke="#3397EF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </span>
                                        </a>}
                                    </div>
                                    <div className="PsnlDetailsMentors">
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/usermentor.png" alt="" />
                                            </span>
                                            <p className="text2">{particularUser?.profession?.title ? particularUser?.profession?.title === "Work" ? "Working Professional" : "Student" : "---"}</p>
                                        </div>
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/buliding.png" alt="" />
                                            </span>
                                            <p className="text2">{particularUser?.email ?? "---"}</p>
                                        </div>
                                        <div className="MentorSubDetails f-items-center">
                                            <span className="icontools flex-center">
                                                <img src="/assets/img/contact/buliding.png" alt="" />
                                            </span>
                                            <p className="text2">{particularUser?.mobileNumber ?? "---"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="CardsProfilWrapper">
                                    <ProfileCard number={particularUser?.noOfCalls ?? 0} text={'Number of Calls'} img={"/assets/img/contact/call.png"} />
                                    <ProfileCard className={'purpleCard'} number={particularUser?.credits ?? 0} text={'Total Credits'} img={"/assets/img/contact/User1.png"} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="MenusTabWrapper w-100">
                        <ul className='flex gap-30 items-center'>
                            <li onClick={() => setTabSlotProfile(1)} className={TabSlotProfile === 1 && 'active'}>Overview</li>
                            <li onClick={() => setTabSlotProfile(2)} className={TabSlotProfile === 2 && 'active'}>Call History</li>
                        </ul>
                    </div>

                </div>

            </div>

            <OverviewMentees isActiveTab={TabSlotProfile === 1 && 'active'} data={particularUser} />
            <CallHistoryMentees isActiveTab={TabSlotProfile === 2 && 'active'} isFetching={isFetching} isUpcomingCalls={isUpcomingCalls} setIsUpcomingCalls={setIsUpcomingCalls} upcomingCalls={totalUpcomingCalls} pastCalls={totalPastCalls} upcoming={userUpcomingCalls} past={userPastCalls} handleLoadMore={handleLoadMore} onClickViewFeedback={handleViewFeedback} />

            {/* <PopFeedBack /> */}

        </section>
        </>
    )
}

export default MenteesProfile