import React, { useEffect, useState } from 'react'
import NotificationDropDown from '../../components/PopUp/NotificationDropDown/NotificationDropDown'
import ProfileDropDown from '../../components/PopUp/ProfileDropDown/ProfileDropDown'
import './HeaderStyle.css'
import { useNavigate } from 'react-router-dom';

function Header({onClickMenu,setOnClickMenu}) {
  // == Notification ==
  const [isOpenMeg, setIsOpenMeg] = useState(false);
  // == Profile ==
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  return (
    <header className='Header'>
      <div className="headerWrapper flex-between">
        {windowWidth >= 767 ? <h2>Welcome! <span>Admin</span></h2> : <img src="/assets/svg/Logo.svg" alt="" className='pointer' onClick={() => { navigate('/') }} /> }
        <div className="rightheader">
          {/* <img src="/assets/svg/search.svg" alt=""  /> */}
          {windowWidth >= 767 ? 
          <>
          {/* <div className="NotificationIcon flex-center active " onClick={() => { setIsOpenMeg(!isOpenMeg); setIsOpen(false) }}>
            <img src="/assets/svg/notification.svg" alt="" className='notification1 pointer' />
            <NotificationDropDown className="ProfileDrapdownMain" isActive={isOpenMeg} setIsActive={setIsOpenMeg} />
          </div>  */}
          <div className="ProfileImageUsers" onClick={() => { setIsOpen(!isOpen); setIsOpenMeg(false); }}>
            <img src="/assets/img/User.png" alt="" className='pointer headerProfileImg' />
            <ProfileDropDown className="ProfileDrapdownMain" isActive={isOpen} setIsActive={setIsOpen} />
          </div>
          </>
          : <button className={`hamButton ${onClickMenu === true ? 'active':''}`} onClick={()=> setOnClickMenu(!onClickMenu)}>
              <span className="hamLines"></span>
              <span className="hamLines"></span>
              <span className="hamLines"></span>
            </button>
      }
        </div>

      </div>
    </header>
  )
}

export default Header